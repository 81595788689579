import { OrStepperProps, AtStepProps } from '@curran-catalog/curran-atomic-library'
import { ContentfulOrStepper, ContentfulAtStep } from 'types/contentful'

export const normalizeOrStepper = (stepper: ContentfulOrStepper): OrStepperProps => {
  const steps = stepper.steps.map((step, index) => normalizeAtStep(step, index + 1))
  return {
    steps,
  }
}

export const normalizeAtStep = (step: ContentfulAtStep, id: number): AtStepProps => {
  return {
    description: { text: step.description },
    id: id,
  }
}
