// select_item => ObjAnalytics | FlooringCatalogHit | FurnitureHit
// view_item_list => ObjAnalytics | FlooringCatalogHit | FurnitureHit
// view_item => ObjFlooring | ObjFurniture | ColorSliderAnalyticsData
import { ContentfulObjAnalytics, ContentfulObjFlooringWeave, ContentfulObjFurnitureProduct } from '../../../../types'
import { FlooringCatalogHit, FurnitureCatalogHit } from '../../../../types/algolia/catalog-index'
import { detectSiteDomain } from '@utils/detect-site-domain'
import { createSelectItemDTO } from '@utils/analytics/events/measuring-product/select-item'
import { createViewItemListDTO } from '@utils/analytics/events/measuring-product/view-item-list'
import { createViewItemDTO } from '@utils/analytics/events/measuring-product/view-item'
import { trackAnalyticsEvent } from '@utils/analytics/events/track-event'
import {
  ColorSliderAnalyticsData,
  ProductMeasureEventDTO,
  ProductMeasureEventTypes,
} from '../../../../types/analytics/analytics-data'

export type sendProductMeasureInputData =
  | ContentfulObjAnalytics
  | FlooringCatalogHit
  | FurnitureCatalogHit
  | ContentfulObjFurnitureProduct
  | ContentfulObjFlooringWeave
  | ColorSliderAnalyticsData

/**
 * Tracks an event by sending it into the datalayer, specifically events related
 * to product analytics such as: view_item, view_item_list, and select_item
 * @param inputData
 * @param eventType
 * @param index
 * @param price
 * @param previousRoute
 */
export const sendProductMeasureAnalyticsEvent = (
  inputData: sendProductMeasureInputData,
  eventType: ProductMeasureEventTypes,
  index: number,
  price?: number,
  previousRoute?: string,
) => {
  if (!inputData) {
    return
  }

  const siteDomainInfo = detectSiteDomain()

  let productMeasureEventDTO: ProductMeasureEventDTO

  switch (eventType) {
    case ProductMeasureEventTypes.SELECT_ITEM:
      productMeasureEventDTO = createSelectItemDTO(
        inputData as ContentfulObjAnalytics | FlooringCatalogHit | FurnitureCatalogHit,
        siteDomainInfo,
        index,
      )
      break
    case ProductMeasureEventTypes.VIEW_ITEM_LIST:
      productMeasureEventDTO = createViewItemListDTO(
        inputData as ContentfulObjAnalytics | FlooringCatalogHit | FurnitureCatalogHit,
        siteDomainInfo,
        index,
      )
      break
    case ProductMeasureEventTypes.VIEW_ITEM:
      productMeasureEventDTO = createViewItemDTO(
        inputData as ContentfulObjFlooringWeave | ContentfulObjFurnitureProduct | ColorSliderAnalyticsData,
        siteDomainInfo,
        index,
        price as number,
        previousRoute as string,
      )
      break
  }

  if (productMeasureEventDTO) {
    trackAnalyticsEvent(productMeasureEventDTO)
  }
}
