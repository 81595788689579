import { OrHeaderProps } from '@curran-catalog/curran-atomic-library'

import { normalizeLink } from './link'
import { normalizeAsset } from '.'
import { AlgoliaWrapper, CustomOrSearch } from '@components/searchbox'
import { ContentfulOrHeader } from 'types'
import { Results } from '@components/searchbox/Results'
import { detectSiteDomain } from '@utils/detect-site-domain'

import { algoliaConfig } from '@utils/algolia'

enum SearchboxDefaultValues {
  PLACEHOLDER = 'Search',
  NO_RESULTS = 'No results have been found for',
  PRODUCTS = 'Products',
  PAGES = 'Categories & Pages',
}

const { synsisalSite } = detectSiteDomain()

export const normalizeHeader = (header: ContentfulOrHeader, path: string): OrHeaderProps => {
  const { logoLink, navLinks, externalSiteLink, searchComponent } = header

  const normalizedNavLinks = navLinks?.map(({ title, categories }) => ({
    title: normalizeLink(title),
    categories: categories?.map((category) => ({
      title: normalizeLink(category.title),
      subItems: category?.subItems?.map((subItem) => {
        return {
          ...normalizeLink(subItem),
          active: path === subItem.actionUrl,
        }
      }),
    })),
  }))

  return {
    ...(!!header.announcement && {
      announcement: {
        ...header.announcement,
        content: { text: header.announcement.content },
        image: header.announcement.fileImage
          ? normalizeAsset({ asset: { ...header.announcement.fileImage[0], priority: true } })
          : undefined,
        ...{ ...(header.announcement.link && { link: normalizeLink(header.announcement.link) }) },
      },
    }),
    externalSiteLink: normalizeLink(externalSiteLink),
    logoLink: normalizeLink(logoLink, true),
    navLinks: normalizedNavLinks ?? [],
    showSearchComponent: !synsisalSite,
    searchComponent: (
      <AlgoliaWrapper algoliaConfig={algoliaConfig}>
        <CustomOrSearch
          placeholder={searchComponent?.placeholder ?? SearchboxDefaultValues.PLACEHOLDER}
          results={
            <Results
              recordsIndex={algoliaConfig.recordsIndex}
              noResultsFoundLabel={searchComponent?.noResultsFoundLabel ?? SearchboxDefaultValues.NO_RESULTS}
              seeAllResultsLabel={searchComponent?.seeAllResultsLabel ?? SearchboxDefaultValues.PRODUCTS}
              pageLinksIndex={algoliaConfig.pageLinksIndex}
              pageNoResultsLabel={searchComponent?.pageNoResultsLabel ?? SearchboxDefaultValues.NO_RESULTS}
              pageResultsLabel={searchComponent?.pageResultsLabel ?? SearchboxDefaultValues.PAGES}
            />
          }
        />
      </AlgoliaWrapper>
    ),
  } as OrHeaderProps
}
