import { CartEventDTO, CheckoutEventDTO, ProductMeasureEventDTO } from '../../../types/analytics/analytics-data'
import * as fbq from '@utils/analytics/fpixel'

export const trackAnalyticsEvent = (
  eventDTO: ProductMeasureEventDTO | CheckoutEventDTO | CartEventDTO,
  fbqEvent?: string,
): void => {
  window.dataLayer?.push({ ecommerce: null })
  window.dataLayer?.push(eventDTO)
  if (fbqEvent) {
    fbq.trackEvent('InitiateCheckout')
  }
}
