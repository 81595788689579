import { environment } from '@config/environment'
import { OrFormSubmitResponse } from '@curran-catalog/curran-atomic-library'

export const submitNewsLetter = (formValues: FormData) => {
  const formObject: { [key: string]: string } = {}
  formValues.forEach((value, key) => (formObject[key] = value as string))
  return new Promise<OrFormSubmitResponse>((resolve) => {
    const headers = new Headers()

    const formId = environment.newsletter.formId
    const portalId = environment.newsletter.portalId

    headers.append('form-id', formId as string)
    headers.append('portal-id', portalId as string)

    fetch(`/api/newsletter?${new URLSearchParams(formObject).toString()}`, {
      method: 'GET',
      headers,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.code === 200) {
          resolve({ success: true, code: res.code, title: 'Submitted!', message: res.message })
        }
        resolve({ success: false, code: res.code, title: 'Error!', message: res.message })
      })
      .catch((error) => {
        resolve({ success: false, code: error.code, title: 'Error!', message: error.statusText })
      })
  })
}
