import { AtLinkProps, OrSidebarMenuProps } from '@curran-catalog/curran-atomic-library'
import { renderLinkWrapper } from 'utils'

import { ContentfulOrSidebarMenu } from 'types'
import { normalizeLink } from '.'

export const normalizeSidebar = (sidebar: ContentfulOrSidebarMenu, path: string): OrSidebarMenuProps => ({
  ...sidebar,
  menuItems:
    sidebar?.menuItems?.map(({ title, subItems }) => ({
      title: normalizeLink(title),
      subItems: subItems?.map(
        (subItem) =>
          ({
            ...normalizeLink(subItem),
            active: path === subItem.actionUrl,
            linkWrapper: renderLinkWrapper,
          } as AtLinkProps),
      ),
    })) ?? [],
})
