import { feetInchesToFeetConversion } from '@components/rug-builder/order-rug/steps/customize-rug-types/utils'
import { ObjAddonState } from '@context/furniture-product'
import axios from '@utils/axios'
import { MeasureUnitKey } from '@utils/measures'
import { ContentfulObjFlooringWeave, ErrorFurniturePrices, FurniturePrices } from 'types'
import { getFurniturePrices } from './furniture'

export type WeaveParametersDTO = {
  weave: string
}

export type WeaveParametersResponse = WeaveParametersSuccess | WeaveParametersError

export type WeaveParametersSuccess = {
  allowed_shapes: string
  roll_width: string
  bl_min_length: number | null
  cf_min_diameter: number | null
  cf_min_sqft: number | null
  cf_max_sqft: number | null
  cf_max_seams: number | null
  has_swatches: number | null
  rush_price: number | null
  swatch_price: number | null
  border_swatch_price: number | null
}

export type WeaveParametersError = {
  message: string
}

export const getWeaveParameters = async ({ weave }: WeaveParametersDTO) => {
  try {
    const response = await axios.get<WeaveParametersResponse>(`api/floor/${weave}/parameters`)
    return response.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export enum Unit {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

export type RugPadResponse = {
  reference: string
  price: number
  included: boolean
}

export type BorderResponse = {
  reference: string
  width: null | string
  price: number
  rugPads: RugPadResponse[]
  name: string
}

export type AreaPricesResponse = {
  reference: string
  borders: BorderResponse[]
}

export type WallToWallAreaPricesResponse = {
  price: number
}

export type RectangleAreaPricesDTO = {
  isMetric: boolean
  weave: string

  lengthFt?: number
  lengthIn?: number
  widthFt?: number
  widthIn?: number

  lengthCm?: number
  widthCm?: number
}

export type CircleAreaPricesDTO = {
  isMetric: boolean
  weave: string

  diameterFt?: number
  diameterIn?: number
  diameterCm?: number
}

export type WallToWallAreaPricesDTO = {
  weave: string
  isMetric: boolean
  unitMeasure?: MeasureUnitKey
  sqYd?: number
  sqFt?: number
  lft?: number
  lftIn?: number
  sqM?: number
  lm?: number
}

export const getRectangleAreaPrices = ({
  weave,
  isMetric,
  lengthFt,
  lengthIn,
  widthFt,
  widthIn,
  lengthCm,
  widthCm,
}: RectangleAreaPricesDTO) => {
  const imperialParams = `lengthFt=${lengthFt}&lengthIn=${lengthIn}&widthFt=${widthFt}&widthIn=${widthIn}&unit=${Unit.IMPERIAL}`
  const metricParams = `lengthCm=${lengthCm}&widthCm=${widthCm}&unit=${Unit.METRIC}`

  return axios.get<AreaPricesResponse>(
    `/api/floor/pricing/${weave}/area?&shape=rectangle&${isMetric ? metricParams : imperialParams}`,
  )
}

export const getCircleAreaPrices = ({ weave, isMetric, diameterFt, diameterIn, diameterCm }: CircleAreaPricesDTO) => {
  const imperialParams = `diameterFt=${diameterFt}&diameterIn=${diameterIn}&unit=${Unit.IMPERIAL}`
  const metricParams = `diameterCm=${diameterCm}&unit=${Unit.METRIC}`

  return axios.get<AreaPricesResponse>(
    `/api/floor/pricing/${weave}/area?&shape=circle&${isMetric ? metricParams : imperialParams}`,
  )
}

export const getWallToWallAreaPrices = ({
  weave,
  isMetric,
  unitMeasure,
  sqYd,
  sqFt,
  lft,
  lftIn,
  sqM,
  lm,
}: WallToWallAreaPricesDTO) => {
  const sqydParam = `sqyd=${sqYd}`
  const sqftParam = `sqft=${sqFt}`
  const lftInParam = `linearft=${feetInchesToFeetConversion(lft ?? 0, lftIn ?? 0)}`

  const sqmParam = `sqm=${sqM}`
  const lmParam = `linearm=${lm}`

  const getParams = () => {
    if (isMetric) {
      switch (unitMeasure) {
        case MeasureUnitKey.SQUARE_METER:
          return `${sqmParam}&unit=${Unit.METRIC}`

        case MeasureUnitKey.LINEAR_METER:
          return `${lmParam}&unit=${Unit.METRIC}`

        default:
          return `${sqmParam}&unit=${Unit.METRIC}`
      }
    } else {
      switch (unitMeasure) {
        case MeasureUnitKey.SQUARE_YARD:
          return `${sqydParam}&unit=${Unit.IMPERIAL}`

        case MeasureUnitKey.SQUARE_FEET:
          return `${sqftParam}&unit=${Unit.IMPERIAL}`

        case MeasureUnitKey.LINEAR_FEET:
          return `${lftInParam}&unit=${Unit.IMPERIAL}`

        default:
          return `${sqftParam}&unit=${Unit.IMPERIAL}`
      }
    }
  }

  const params = new URLSearchParams(getParams())

  return axios.get<WallToWallAreaPricesResponse>(`/api/floor/pricing/${weave}/broadloom?${params}`)
}

export type WeaveStartingAtPriceDTO = {
  weaveReference: string
}
export type WeaveStartingAtPriceResponse = {
  reference: string
  area_rug_price: number | null
  area_rug_width: number | null
  area_rug_length: number | null
  tile_box_price?: number | null
  bl_w2w_sqyd_price: number | null
}

export const getStartingAtPrices = ({ weaveReference }: WeaveStartingAtPriceDTO) => {
  return axios
    .get<WeaveStartingAtPriceResponse>(`/api/floor/pricing/starting-at-prices/${weaveReference}`)
    .then(({ data }) => data)
    .catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error API stating at pricing')
      return null
    })
}

export interface ObjFlooringWeave extends ContentfulObjFlooringWeave {
  addons?: ObjAddonState[]
}

export const getWeaveWithAddonPrices = async (weave: ContentfulObjFlooringWeave): Promise<ObjFlooringWeave> => {
  let addonPricesResponse: (FurniturePrices | ErrorFurniturePrices | undefined)[] = []

  if (weave && Array.isArray(weave.addons) && weave.addons.length > 0) {
    const addonPrices = weave.addons.map((addon) => getFurniturePrices({ contentfulId: addon.CONTENTFUL_ID }))
    addonPricesResponse = await Promise.all(addonPrices)
  }

  return {
    ...weave,
    ...(weave &&
      Array.isArray(weave.addons) &&
      weave.addons.length > 0 && {
        addons: weave.addons.map((addon) => {
          const foundAddons = addonPricesResponse.find((addonResponse) => {
            if (
              addonResponse &&
              Array.isArray((addonResponse as FurniturePrices).products) &&
              (addonResponse as FurniturePrices).products.length > 0
            ) {
              return (addonResponse as FurniturePrices).productConnectorReference === addon.CONTENTFUL_ID
            }
            return null
          })

          return {
            ...addon,
            ...(foundAddons && { addonPrices: foundAddons as FurniturePrices }),
          }
        }),
      }),
  }
}
