import { AtInfoItemProps } from '@curran-catalog/curran-atomic-library'
import { ContentfulAtInfoItem } from 'types'
import { normalizeAsset } from '.'

export const normalizeInfoItem = (infoItem: ContentfulAtInfoItem, isPriorityImage?: boolean): AtInfoItemProps => {
  return {
    ...infoItem,
    ...{
      ...(Array.isArray(infoItem.iconImage) &&
        infoItem.iconImage.length > 0 && {
          icon: normalizeAsset({
            asset: { ...infoItem.iconImage[0], priority: isPriorityImage },
          }) as AtInfoItemProps['icon'],
        }),
    },
  }
}
