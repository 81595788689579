export const highlightOption = (
  hierarchy: number,
  currentId: string,
  selectedPrimaryComboId?: string,
  selectedSecondaryComboId?: string,
  selectedTertiaryComboId?: string,
  selectedQuaternaryComboId?: string,
) => {
  if (selectedPrimaryComboId && hierarchy === 1) {
    return currentId === selectedPrimaryComboId
  } else if (selectedSecondaryComboId && hierarchy === 2) {
    return currentId === selectedSecondaryComboId
  } else if (selectedTertiaryComboId && hierarchy === 3) {
    return currentId === selectedTertiaryComboId
  } else if (selectedQuaternaryComboId && hierarchy === 4) {
    return currentId === selectedQuaternaryComboId
  }

  return false
}
