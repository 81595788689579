import { ContentfulObjAnalytics, ContentfulObjFlooringWeave, ContentfulObjFurnitureProduct } from '../../../types'
import { getCurrentSynsisalRoute } from '@utils/get-synsisal-route'
import { siteNameStore } from '@utils/analytics/common'
import { DetectSiteDomain } from '@utils/detect-site-domain'
import { FlooringCatalogHit, FurnitureCatalogHit } from '../../../types/algolia/catalog-index'
import {
  ColorSliderAnalyticsData,
  ProductMeasureEventDTO,
  ProductMeasureEventTypes,
} from '../../../types/analytics/analytics-data'

export const objAnalyticsToProductMeasureDTO = (
  data: ContentfulObjAnalytics,
  index: number,
  siteDomainInfo: DetectSiteDomain,
  event: ProductMeasureEventTypes,
): ProductMeasureEventDTO => {
  const { synsisalSite } = siteDomainInfo

  return {
    event,
    ecommerce: {
      ...(synsisalSite && { item_list_name: getCurrentSynsisalRoute() }),
      items: [
        {
          affiliation: siteNameStore,
          currency: 'USD',
          index,
          ...(synsisalSite && { item_brand: 'synsisal' }),
          item_category: !synsisalSite ? data?.materialFamily?.join(', ') : `Custom Rugs samples`,
          item_id: data.productId,
          item_list_name: !synsisalSite ? document.title || '' : getCurrentSynsisalRoute(),
          item_name: !synsisalSite ? data.name : `${data.productName} Sample`,
          item_stock_detail: 'In_stock',
          price: 0,
          quantity: 1,
        },
      ],
    },
  }
}

export const furnitureCatalogHitToProductMeasureDTO = (
  data: FurnitureCatalogHit,
  index: number,
  event: ProductMeasureEventTypes,
): ProductMeasureEventDTO => {
  return {
    event,
    ecommerce: {
      items: [
        {
          affiliation: siteNameStore,
          currency: 'USD',
          index,
          item_brand: data?.vendorName,
          item_category: data?.category,
          item_category2: data?.category2,
          item_id: data.sku,
          item_list_name: document.title,
          item_name: data.name,
          price: parseFloat(`${data.price}`),
        },
      ],
    },
  }
}

export const flooringCatalogHitToProductMeasureDTO = (
  data: FlooringCatalogHit,
  index: number,
  siteDomainInfo: DetectSiteDomain,
  event: ProductMeasureEventTypes,
): ProductMeasureEventDTO => {
  const { synsisalSite } = siteDomainInfo
  return {
    event,
    ecommerce: {
      ...(synsisalSite && { item_list_name: getCurrentSynsisalRoute() }),
      items: [
        {
          affiliation: siteNameStore,
          currency: 'USD',
          index,
          ...(synsisalSite && { item_brand: 'synsisal' }),
          item_category: !synsisalSite ? data?.materialFamily?.join(', ') : `Custom Rugs samples`,
          item_id: data.objectID,
          item_list_name: !synsisalSite ? document.title || '' : getCurrentSynsisalRoute(),
          item_name: !synsisalSite ? data.name : `${data.name} Sample`,
          item_stock_detail: 'In_stock',
          ...(synsisalSite
            ? { price: 0 }
            : (data?.prices?.areaRug || data?.prices?.w2wSqyd) && {
                price: parseFloat(`${data.prices.areaRug}` || `${data.prices.w2wSqyd}`),
              }),
          quantity: 1,
        },
      ],
    },
  }
}

export const objFlooringWeaveToProductMeasureDTO = (
  data: ContentfulObjFlooringWeave,
  index: number,
  siteDomainInfo: DetectSiteDomain,
  event: ProductMeasureEventTypes,
  price: number,
  previousRoute: string,
): ProductMeasureEventDTO => {
  const { synsisalSite } = siteDomainInfo
  return {
    event,
    ecommerce: {
      ...(synsisalSite && { currency: 'USD' }),
      items: [
        {
          affiliation: siteNameStore,
          currency: 'USD',
          ...(index && { index: index }),
          ...(synsisalSite && { item_brand: 'synsisal' }),
          item_category: !synsisalSite ? data?.materialFamily : `Custom Rugs samples`,
          item_id: data.CONTENTFUL_ID,
          item_list_name: previousRoute,
          item_name: synsisalSite ? `${data.weaveName} Sample` : data.weaveName,
          item_stock_detail: 'In_stock',
          price: synsisalSite ? 0 : price,
          quantity: 1,
        },
      ],
    },
  }
}

export const objFurnitureProductToProductMeasureDTO = (
  data: ContentfulObjFurnitureProduct,
  index: number,
  event: ProductMeasureEventTypes,
  price: number,
  previousRoute: string,
): ProductMeasureEventDTO => {
  return {
    event,
    ecommerce: {
      items: [
        {
          affiliation: siteNameStore,
          currency: 'USD',
          ...(index && { index: index }),
          item_brand: data.vendor?.vendorName,
          item_category: data.category,
          item_category2: data.category2,
          item_id: data.CONTENTFUL_ID,
          item_list_name: previousRoute,
          item_name: data.productName,
          price: price,
          quantity: 1,
        },
      ],
    },
  }
}

export const colorSliderDataToDTO = (
  data: ColorSliderAnalyticsData,
  index: number,
  event: ProductMeasureEventTypes,
  previousRoute: string,
) => {
  const { weaveName, selectedColor } = data
  // Currently only used for Synsisal
  return {
    event,
    ecommerce: {
      items: [
        {
          affiliation: siteNameStore,
          currency: 'USD',
          ...(index && { index: index }),
          itemBrand: 'synsisal',
          item_category: 'Custom Rugs samples',
          item_id: selectedColor?.CONTENTFUL_ID,
          item_list_name: previousRoute ?? getCurrentSynsisalRoute(),
          item_name: `${weaveName}: ${selectedColor?.weaveColorName} Sample`,
          item_stock_detail: 'In_stock',
          item_variant: selectedColor?.weaveColorName,
          price: 0,
          quantity: 1,
        },
      ],
    },
  }
}
