import { OrNewsLetterProps } from '@curran-catalog/curran-atomic-library'

import { ContentfulOrNewsletter } from 'types'
import { normalizeButton } from '.'
import { normalizeFormField } from './form-field'
import { environment } from '@config/environment'
import { submitNewsLetter } from '@services/ecommerce/submit-newsletter'
import toast from 'react-hot-toast'

export const normalizeNewsLetter = (newsLetter: ContentfulOrNewsletter): OrNewsLetterProps => {
  const { button, input } = newsLetter

  return {
    ...newsLetter,
    button: normalizeButton(button),
    input: { ...normalizeFormField(input), autoComplete: 'email' },
    form: {
      id: environment.newsletter.portalId,
      name: environment.newsletter.portalId,
      onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
        const target = event.target as HTMLFormElement
        const formData = new FormData(target)

        return submitNewsLetter(formData).then((response) => {
          if (response.success) {
            toast.success(response.message)
            target.reset()
          } else {
            toast.error(response.message)
          }
        })
      },
    },
  } as OrNewsLetterProps
}
