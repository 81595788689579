import { RefObject } from 'react'
import { NextRouter } from 'next/router'
import { Hit } from 'instantsearch.js'
import { AtLinkProps, MlProductCardProps, OrProductListingProps } from '@curran-catalog/curran-atomic-library'

import { normalizeAsset } from '.'
import { formatPrice, renderLinkWrapper, setAlgoliaQueryIdHistory } from '..'
import { normalizeButton } from './button'
import { TmCatalogProps } from 'pages/catalog/[[...slug]]'
import { environment } from '@config/environment'
import { hitsPerPage } from '@services/algolia'
import { Cart, ContentfulSynsisalWeaveColorGroup, SiteDomain } from 'types'
import { SendEventForHits } from 'instantsearch.js/es/lib/utils'
import { FlooringCatalogHit, FurnitureCatalogHit } from '../../types/algolia/catalog-index'
import { sendProductMeasureAnalyticsEvent } from '@utils/analytics/events/measuring-product/send-event'
import { ProductMeasureEventTypes } from '../../types/analytics/analytics-data'

const DEFAULT_CARD_DIMENSION = 500
const SYNSISAL_DEFAULT_CARD_DIMENSION = 608
const DEFAULT_THUMBNAIL_CARD_DIMENSION = 42
const LIMIT_PRIORITY_CARD_IMAGES = 5
export const PRODUCT_PAGE_SLUG = environment.siteDomain === SiteDomain.FURNITURE ? 'products' : 'rugs'
const isSynsisalSite = environment.siteDomain === SiteDomain.SYNSISAL

const normalizeCTACard = (ctaCard: TmCatalogProps['ctaCard']) =>
  ctaCard && {
    title: ctaCard.title,
    button: normalizeButton(ctaCard.button),
    description: { text: ctaCard.description },
  }

const handleMlProductClick = ({
  product,
  router,
  objectID,
  setStoredObjectID,
  productIndexId,
  setStoredProductIndexID,
  sendEvent,
}: {
  product: Hit
  router: NextRouter | null
  objectID: string | null
  setStoredObjectID: (objectID: string) => void
  productIndexId: number
  setStoredProductIndexID: (productIndexId: number) => void
  sendEvent?: SendEventForHits
}) => {
  if (typeof window !== 'undefined' && objectID) {
    // Add the objectID to session storage
    setStoredObjectID(objectID)
    setStoredProductIndexID(productIndexId)
    sendProductMeasureAnalyticsEvent(
      product as FlooringCatalogHit | FurnitureCatalogHit,
      ProductMeasureEventTypes.SELECT_ITEM,
      productIndexId,
    )
    sendEvent && sendEvent('click', product, 'Catalog Result Clicked')
    setAlgoliaQueryIdHistory(product)
  }

  // Gets the page to which the hit-card belongs
  // using the __position field of hits and hitsPerPage
  const page = Math.ceil(Number(product['__position']) / hitsPerPage)

  const getQuery = () => {
    if (page > 1) {
      return { ...(router && router.query), page }
    } else {
      return router && router.query
    }
  }

  // Update the url of the page keeping the pathname and only modifying the query-parameter page
  return (
    router &&
    router.replace(
      {
        pathname: router && router.pathname,
        query: getQuery(),
      },
      undefined,
      {
        shallow: true,
        scroll: false,
      },
    )
  )
}
const handleMlProductIntersectionView = ({
  product,
  productIndexId,
}: {
  product: Hit
  objectID: string | null
  productIndexId: number
}) => {
  sendProductMeasureAnalyticsEvent(
    product as FlooringCatalogHit | FurnitureCatalogHit,
    ProductMeasureEventTypes.VIEW_ITEM_LIST,
    productIndexId,
  )
}

type normalizeProductCardsProps = {
  products: Hit[]
  ref: RefObject<HTMLDivElement>
  router: NextRouter | null
  objectID: string | null
  setStoredObjectID: (objectID: string) => void
  setStoredProductIndexID: (productIndexId: number) => void
  sendEvent?: SendEventForHits
}

const normalizeProductCards = ({
  products,
  ref,
  router,
  objectID,
  setStoredObjectID,
  setStoredProductIndexID,
  sendEvent,
}: normalizeProductCardsProps): MlProductCardProps[] =>
  products.map((product, index) => {
    const mainImages =
      Array.isArray(product.images) && product.images.length > 0
        ? product.images.map((image) =>
            normalizeAsset({
              asset: {
                ...image,
                priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
                width: DEFAULT_CARD_DIMENSION,
                height: DEFAULT_CARD_DIMENSION,
              },
            }),
          )
        : [{ src: '/images/image-coming-soon.jpg', alt: '' }]

    const thumbnailImages =
      Array.isArray(product.images) && product.images.length > 0
        ? product.images.map((image) =>
            normalizeAsset({
              asset: {
                ...image,
                priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
                width: DEFAULT_THUMBNAIL_CARD_DIMENSION,
                height: DEFAULT_THUMBNAIL_CARD_DIMENSION,
              },
            }),
          )
        : [{ src: '/images/image-coming-soon.jpg', alt: '' }]

    const hoverImage = product.hoverImage
      ? normalizeAsset({
          asset: {
            ...product.hoverImage,
            priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
            width: DEFAULT_CARD_DIMENSION,
            height: DEFAULT_CARD_DIMENSION,
          },
        })
      : undefined

    return {
      index: index + 1,
      title: {
        label: product.name,
        actionUrl: `/${PRODUCT_PAGE_SLUG}/${product.nameSlug}`,
        // Wrap the ml-product card with next-link
        linkWrapper: renderLinkWrapper,
      } as AtLinkProps,
      mainImages,
      hideTooltip: true,
      thumbnailImages,
      hoverImage,
      price: formatPrice(product.price),
      objectID: product.objectID,
      ref: product.objectID === objectID ? ref : null,
      clickHandler: (objectID, index) =>
        handleMlProductClick({
          product,
          router,
          objectID,
          setStoredObjectID,
          productIndexId: index,
          setStoredProductIndexID,
          sendEvent,
        }),
      intersectionHandler: (objectID, index) =>
        handleMlProductIntersectionView({
          product: product,
          objectID: objectID,
          productIndexId: index,
        }),
    }
  })

type normalizeWeaveCardsProps = {
  products: Hit[]
  ref: RefObject<HTMLDivElement>
  router: NextRouter | null
  objectID: string | null
  setStoredObjectID: (objectID: string) => void
  setStoredProductIndexID: (productIndexId: number) => void
  setActiveProduct?: (activeProduct: Hit) => void
  cart?: Cart
  sendEvent?: SendEventForHits
}
const normalizeWeaveCards = ({
  products,
  ref,
  router,
  objectID,
  setStoredObjectID,
  setStoredProductIndexID,
  setActiveProduct,
  cart,
  sendEvent,
}: normalizeWeaveCardsProps): MlProductCardProps[] =>
  products.map((product, index) => {
    const mainImages =
      Array.isArray(product.images) && product.images.length > 0
        ? product.images.map((image) => {
            return normalizeAsset({
              asset: {
                ...image,
                priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
                width: DEFAULT_CARD_DIMENSION,
                height: DEFAULT_CARD_DIMENSION,
              },
            })
          })
        : [{ src: '/images/image-coming-soon.jpg', alt: '' }]

    const thumbnailImages =
      Array.isArray(product.images) && product.images.length > 0
        ? product.images.map((image) =>
            normalizeAsset({
              asset: {
                ...image,
                priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
                width: DEFAULT_THUMBNAIL_CARD_DIMENSION,
                height: DEFAULT_THUMBNAIL_CARD_DIMENSION,
              },
            }),
          )
        : [{ src: '/images/image-coming-soon.jpg', alt: '' }]

    const hoverImage = product.hoverImage
      ? normalizeAsset({
          asset: {
            ...product.hoverImage,
            priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
            width: DEFAULT_CARD_DIMENSION,
            height: DEFAULT_CARD_DIMENSION,
          },
        })
      : undefined

    const showStartingAtLabel = !!product.prices.areaRug && !product.callForQuote
    const w2wPrice = `${formatPrice(product.prices.w2wSqyd ?? 0)} sqyd`

    let price = ''
    if (product.callForQuote) price = 'Request Quote'
    else if (product.typeSlugs?.includes('area-rugs')) price = formatPrice(product.prices.areaRug ?? 0)
    else if (product.typeSlugs?.includes('tiles-and-planks'))
      price = `${formatPrice(product.prices.tileBox ?? 0)} / Box`
    else if (product.typeSlugs?.includes('wallcovering')) price = `${formatPrice(product.prices.w2wSqyd ?? 0)} sqyd`

    return {
      index: index + 1,
      title: {
        label: product.name,
        actionUrl: `/${PRODUCT_PAGE_SLUG}/${product.nameSlug}`,
        // Wrap the ml-product card with next-link
        linkWrapper: renderLinkWrapper,
      } as AtLinkProps,
      isImageFit: true,
      hideTooltip: true,
      mainImages,
      thumbnailImages,
      hoverImage,
      price,
      showStartingAtLabel,
      wallToWall: product.prices.w2wSqyd && product.typeSlugs?.includes('wall-to-wall') ? w2wPrice : '',
      objectID: product.objectID,
      ref: product.objectID === objectID ? ref : null,
      clickHandler: (objectID, index) =>
        handleMlProductClick({
          product,
          router,
          objectID,
          setStoredObjectID,
          productIndexId: index,
          setStoredProductIndexID,
          sendEvent,
        }),
      intersectionHandler: (objectID, index) =>
        handleMlProductIntersectionView({
          product: product,
          objectID: objectID,
          productIndexId: index,
        }),
      actionClickHandler: () => {
        setActiveProduct && setActiveProduct(product)
      },
      action: {
        role: 'button',
        label: cart?.items?.find((item) => item?.metadata?.brand === product.objectID)
          ? 'Add More Samples'
          : 'Add Samples To Cart',
        icon: {
          size: 16,
          className: 'tw-mr-1',
          type: 'plus',
        },
      } as AtLinkProps,
    }
  })

type normalizeSynsisalCardsProps = {
  products: Hit[]
  ref: RefObject<HTMLDivElement>
  router: NextRouter | null
  objectID: string | null
  setStoredObjectID: (objectID: string) => void
  setStoredProductIndexID: (productIndexId: number) => void
  setActiveProduct?: (activeProduct: Hit) => void
  synsisalWeaveColorGroupCollection?: ContentfulSynsisalWeaveColorGroup[]
  cart?: Cart
  sendEvent?: SendEventForHits
}
const normalizeSynsisalCards = ({
  products,
  ref,
  router,
  objectID,
  setStoredObjectID,
  setStoredProductIndexID,
  setActiveProduct,
  synsisalWeaveColorGroupCollection,
  cart,
  sendEvent,
}: normalizeSynsisalCardsProps): MlProductCardProps[] =>
  products.map((product, index) => {
    const mainImages =
      Array.isArray(product.images) && product.images.length > 0
        ? product.images.map((image) => {
            return normalizeAsset({
              asset: {
                ...image,
                priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
                width: DEFAULT_CARD_DIMENSION,
                height: DEFAULT_CARD_DIMENSION,
              },
            })
          })
        : [{ src: '/images/image-coming-soon.jpg', alt: '' }]

    const thumbnailImages =
      Array.isArray(product.images) && product.images.length > 0
        ? product.images.map((image) =>
            normalizeAsset({
              asset: {
                ...image,
                priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
                width: DEFAULT_THUMBNAIL_CARD_DIMENSION,
                height: DEFAULT_THUMBNAIL_CARD_DIMENSION,
              },
            }),
          )
        : [{ src: '/images/image-coming-soon.jpg', alt: '' }]

    const hoverImage = product.hoverImage
      ? normalizeAsset({
          asset: {
            ...product.hoverImage,
            priority: index <= LIMIT_PRIORITY_CARD_IMAGES,
            width: SYNSISAL_DEFAULT_CARD_DIMENSION,
            height: SYNSISAL_DEFAULT_CARD_DIMENSION,
          },
        })
      : undefined

    const price = 'Free'

    const weaveColorsIds = synsisalWeaveColorGroupCollection
      ?.find((item) => item.CONTENTFUL_ID === product.objectID)
      ?.colorGroup?.weaveColors?.map((item) => item.CONTENTFUL_ID)

    return {
      index: index + 1,
      title: {
        label: product.name,
        actionUrl: `/${PRODUCT_PAGE_SLUG}/${product.nameSlug}`,
        // Wrap the ml-product card with next-link
        linkWrapper: renderLinkWrapper,
      } as AtLinkProps,
      isImageFit: true,
      hideTooltip: true,
      mainImages,
      thumbnailImages,
      hoverImage,
      price,
      objectID: product.objectID,
      ref: product.objectID === objectID ? ref : null,
      clickHandler: (objectID, index) =>
        handleMlProductClick({
          product,
          router,
          objectID,
          setStoredObjectID,
          productIndexId: index,
          setStoredProductIndexID,
          sendEvent,
        }),
      intersectionHandler: (objectID, index) =>
        handleMlProductIntersectionView({
          product: product,
          objectID: objectID,
          productIndexId: index,
        }),
      actionClickHandler: () => {
        setActiveProduct && setActiveProduct(product)
      },
      showPricesLabel: false,
      action: {
        role: 'button',
        label: cart?.items?.find((item) => weaveColorsIds?.includes(item?.metadata?.contentful_id ?? 'not-found'))
          ? 'Add More Samples'
          : 'Add Samples To Cart',
        icon: {
          size: 16,
          className: 'tw-mr-1',
          type: 'plus',
        },
      } as AtLinkProps,
    }
  })

export const normalizeProductListing = (
  hits: Hit[],
  ref: RefObject<HTMLDivElement>,
  router: NextRouter | null,
  objectID: string | null,
  setStoredObjectID: (objectID: string) => void,
  setStoredProductIndexID: (productIndexId: number) => void,
  ctaCard?: TmCatalogProps['ctaCard'],
  setActiveProduct?: (activeProduct: Hit) => void,
  synsisalWeaveColorGroupCollection?: ContentfulSynsisalWeaveColorGroup[],
  cart?: Cart,
  sendEvent?: SendEventForHits,
): OrProductListingProps => {
  const ctaCardNormalized = ctaCard ? normalizeCTACard(ctaCard) : undefined

  let productCards: MlProductCardProps[] = []

  switch (environment.siteDomain) {
    case SiteDomain.FURNITURE:
      productCards = normalizeProductCards({
        products: hits,
        ref,
        router,
        objectID,
        setStoredObjectID,
        setStoredProductIndexID,
        sendEvent,
      })
      break
    case SiteDomain.SYNSISAL:
      productCards = normalizeSynsisalCards({
        products: hits,
        ref,
        router,
        objectID,
        setStoredObjectID,
        setStoredProductIndexID,
        setActiveProduct,
        synsisalWeaveColorGroupCollection,
        cart,
        sendEvent,
      })
      break
    default:
      productCards = normalizeWeaveCards({
        products: hits,
        ref,
        router,
        objectID,
        setStoredObjectID,
        setStoredProductIndexID,
        setActiveProduct,
        cart,
        sendEvent,
      })
  }

  return {
    cards: ctaCardNormalized ? [ctaCardNormalized, ...productCards] : productCards,
    isFiltersOpen: true,
    hasFilters: !isSynsisalSite,
  }
}
