import { ReactNode } from 'react'
import {
  AtImage,
  AtInfoItem,
  MlRichTextProps,
  OrCollapseContainerProps,
  OrCollapseProps,
  OrStepper,
  toKebabCase,
} from '@curran-catalog/curran-atomic-library'

import { normalizeInfoItem, normalizeAsset } from '../normalize'
import { normalizeOrStepper } from '.'
import { cleanContentfulEntry } from '..'
import { CloudinaryAsset, ContentfulOrCollapse, ContentfulOrCollapseContainer, ContentType } from 'types'

export const normalizeCollapse = (
  collapse: ContentfulOrCollapse,
  index?: number,
  isPriorityImage = false,
): OrCollapseProps => {
  const listContent: MlRichTextProps[] = []

  collapse.content.forEach((item) => {
    listContent.push({
      text: item.content,
      customRenderNode: {
        'embedded-entry-block': (node, children: ReactNode) => {
          if (node.data.target.sys.contentType.sys.id === ContentType.AT_CLOUDINARY_ASSET) {
            return node.data.target?.fields?.image?.map((img: CloudinaryAsset, idx: number) => (
              <AtImage
                key={idx}
                {...normalizeAsset({ asset: { ...img, priority: isPriorityImage } })}
                className="w-fit mx-auto"
              />
            ))
          }

          if (node.data.target.sys.contentType.sys.id === ContentType.AT_INFO_ITEM && node.data.target?.fields)
            return <AtInfoItem {...normalizeInfoItem(node.data.target?.fields)} />

          if (node.data.target.sys.contentType.sys.id === ContentType.OR_STEPPER)
            return <OrStepper {...normalizeOrStepper(cleanContentfulEntry(node.data.target))} />

          return children
        },
      },
    })
  })

  return {
    ...collapse,
    idTarget: toKebabCase(collapse.name),
    isOpen: true,
    id: index ?? 0,
    content: listContent,
  }
}

export const normalizeCollapseContainer = (
  containerContent: ContentfulOrCollapseContainer,
): OrCollapseContainerProps => {
  const collapseArray = containerContent.collapseItems.map((collapse, index) => normalizeCollapse(collapse, index))

  return {
    ...containerContent,
    collapseItems: collapseArray,
    id: toKebabCase(containerContent.name),
  }
}
