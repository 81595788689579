import { MlContactProps, MlContactChatStatus, AtLinkProps } from '@curran-catalog/curran-atomic-library'
import { useEffect, useState } from 'react'

import { ContentfulMlContact } from 'types'
import { normalizeLink } from '.'

/**
 * Hubspot Docs: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 */
const chatLabels: Record<MlContactChatStatus, string> = {
  [MlContactChatStatus.PENDING]: 'Pending',
  [MlContactChatStatus.OFFLINE]: 'Offline',
  [MlContactChatStatus.ONLINE]: 'Online Now',
}

export const useNormalizeContact = (contact: ContentfulMlContact): MlContactProps => {
  const [chatStatus, setChatStatus] = useState<MlContactChatStatus>(MlContactChatStatus.PENDING)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        const status = window.HubSpotConversations.widget.status()
        if (status.loaded) {
          setChatStatus(MlContactChatStatus.ONLINE)
          clearInterval(intervalId)
        } else {
          setChatStatus(MlContactChatStatus.PENDING)
        }
      } else {
        setChatStatus(MlContactChatStatus.OFFLINE)
        clearInterval(intervalId)
      }
    }, 2000)

    return () => clearInterval(intervalId)
  }, [])

  const onChatClick = () => {
    if (window.HubSpotConversations && chatStatus === MlContactChatStatus.ONLINE)
      window.HubSpotConversations.widget.open()
  }

  const normalizedChatLink: AtLinkProps = {
    ...normalizeLink(contact.chat),
    label: chatLabels[chatStatus],
    onClick: onChatClick,
  }

  return {
    ...contact,
    usPhoneNumber: normalizeLink(contact.usPhoneNumber),
    internationalNumber: normalizeLink(contact.internationalNumber),
    chat: normalizedChatLink,
    chatStatus,
    email: normalizeLink(contact.email),
  }
}
