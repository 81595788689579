import { SearchClient } from 'algoliasearch'
import { MultipleQueriesQuery, MultipleQueriesResponse } from '@algolia/client-search'
import { environment } from '@config/environment'
import { CartItem, SiteDomain } from 'types/common'
import { BooleanFlooringFacetFiltersKey, FlooringFacetFiltersKey, FurnitureFacetFiltersKey } from 'types'
import aa from 'search-insights'
import { Hit } from 'instantsearch.js'
import { algoliaClient } from '@services/algolia'

export const booleanFilters = (nestedPath: string) => {
  const filters: { [key: string]: string } = {
    'boat-flooring': 'boat:true',
    circular: 'circle:true',
    commercial: 'commercial:true',
    'extra-wide': 'extraWide:true',
    outdoor: 'outdoor:true',
    runners: 'runners:true',
    'stair-runners': 'stairRunners:true',
    europe: 'stockedInEurope:true',
    'walk-off': 'walkOff:true',
  }
  return filters[nestedPath] ?? ''
}

export const getOptimizedClient = (algoliaClient: SearchClient): SearchClient => ({
  ...algoliaClient,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search: (queries: readonly MultipleQueriesQuery[]): Readonly<Promise<MultipleQueriesResponse<any>>> => {
    if (queries.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: queries.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 1,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      })
    } else if (environment.siteDomain === SiteDomain.SYNSISAL) {
      const filteredQueries = queries.map(({ indexName, params }) => ({
        indexName,
        params: {
          ...params,
          filters: `${
            indexName === environment.algolia.pageLinksIndex
              ? `sites:${environment.siteDomain}`
              : 'materialFamilySlug:synsisal'
          }`,
        },
      }))

      return algoliaClient.search(filteredQueries)
    }
    const filteredQueries = queries.map(({ indexName, params }) => ({
      indexName,
      params: {
        ...params,
        filters: `${indexName === environment.algolia.pageLinksIndex ? `sites:${environment.siteDomain}` : ''}`,
      },
    }))

    return algoliaClient.search(filteredQueries)
  },
})

export const getDisjunctiveFacets = (facets: Record<string, Record<string, number>> | undefined) => {
  const disjunctiveFacets: (Record<string, string[]> | undefined)[] = []

  for (const [key, value] of Object.entries(facets ?? {})) {
    const facet: Record<string, string[]> = { [key]: Object.keys(value) }
    disjunctiveFacets.push(facet)
  }

  return disjunctiveFacets
}

export const getMatchedFacets = ({
  allFacets,
  pathArray = [],
}: {
  allFacets: (Record<string, string[]> | undefined)[]
  pathArray: string[] | []
}) => {
  const isSinglePath = pathArray.length === 1

  const furnitureFacetFilters: { [key in FurnitureFacetFiltersKey]: string } = {
    ['category2Slug']: pathArray[0], // catalog/lounging
    ['categorySlug']: isSinglePath
      ? pathArray[0] // catalog/loungers
      : pathArray[1], // catalog/lounging/loungers
    ['collectionSlug']: isSinglePath
      ? pathArray[0] //catalog/steel
      : pathArray[2], // catalog/lounging/loungers/steel
    ['materialSlugs']: isSinglePath ? pathArray[0] : pathArray[2],
    ['vendorSlug']: isSinglePath ? pathArray[0] : pathArray[2],
  }

  const flooringBooleanFacetFilters: { [path: string]: BooleanFlooringFacetFiltersKey } = {
    'boat-flooring': 'boat', // catalog/boat-flooring
    circular: 'circle', // catalog/circular
    commercial: 'commercial', // catalog/commercial
    'extra-wide': 'extraWide', // catalog/extra-wide
    outdoor: 'outdoor', // catalog/outdoor
    runners: 'runners', // catalog/runners
    'stair-runners': 'stairRunners', // catalog/stair-runners
    europe: 'stockedInEurope', // catalog/europe
    'walk-off': 'walkOff', // catalog/walk-off
  }

  const flooringFacetFilters: { [key in FlooringFacetFiltersKey]: string } = {
    ['materialFamilySlug']: isSinglePath ? pathArray[0] : pathArray[2],
    ['materialTypeSlug']: isSinglePath ? pathArray[0] : pathArray[2],
    ['patternSlugs']: isSinglePath ? pathArray[0] : pathArray[2],
    ['textureSlug']: isSinglePath ? pathArray[0] : pathArray[2],
    ['typeSlugs']: isSinglePath ? pathArray[0] : pathArray[1],
    ['usageSlugs']: isSinglePath ? pathArray[0] : pathArray[2],
  }

  const facetFilters = environment.siteDomain === SiteDomain.FURNITURE ? furnitureFacetFilters : flooringFacetFilters

  const matchingFacets: Record<string, string>[] = []
  // Check filters by boolean facets
  // Check if some of the pathArray elements correspond to a flooringFacetFilter
  pathArray.some((path) => {
    const matchingFlooringFilter = flooringBooleanFacetFilters[path]

    if (matchingFlooringFilter && environment.siteDomain !== SiteDomain.FURNITURE) {
      matchingFacets.push({ key: matchingFlooringFilter, value: 'true' })
    }
  })

  // Check filters by nested-routes facets
  Object.entries(facetFilters).forEach(([key, value]) => {
    const matchingFacet = allFacets?.find((facet) => facet && facet[key] && facet[key].includes(value))

    if (matchingFacet) {
      matchingFacets.push({ key, value })
    }
  })

  const parsedMatchingFacets = matchingFacets.map(({ key, value }) => `${key}:${value}`).join(' AND ')

  return parsedMatchingFacets
}

export const attemptToSendAlgoliaQueryIds = ({ cartItems }: { cartItems: CartItem[] }) => {
  try {
    const cartItemsIds = cartItems
      .map((item) => {
        if (item.metadata && item.metadata.query_id) {
          const id = item.metadata.contentful_id
          return id ?? ''
        } else return ''
      })
      .filter((id) => !!id)

    const cartItemsQueryIds = cartItems.map((item) => item.metadata?.query_id ?? '').filter((query) => !!query)

    const algoliaObjectData = cartItemsQueryIds.map((query) => ({ queryID: query ?? '' }))

    aa('purchasedObjectIDsAfterSearch', {
      index: environment.algolia.recordsIndex,
      eventName: 'Order Placed',
      objectIDs: cartItemsIds,
      objectData: algoliaObjectData,
      queryID: cartItemsQueryIds[0],
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error Occurred While Sending The Query Ids To Algolia', error)
    return undefined
  }
}

export const setAlgoliaQueryIdHistory = (hit: Hit) => {
  const queryId = {
    [hit.objectID]: hit.__queryID,
  }
  const algoliaQueryIdHistory = localStorage.getItem('AlgoliaQueryIdHistory')

  if (algoliaQueryIdHistory) {
    const algoliaQueryIdHistoryObj = JSON.parse(algoliaQueryIdHistory)
    const newAlgoliaQueryIdHistory = {
      ...algoliaQueryIdHistoryObj,
      ...queryId,
    }
    localStorage.setItem('AlgoliaQueryIdHistory', JSON.stringify(newAlgoliaQueryIdHistory))
  } else {
    localStorage.setItem('AlgoliaQueryIdHistory', JSON.stringify(queryId))
  }
}

export type AlgoliaConfig = {
  searchClient: SearchClient
  recordsIndex: string
  pageLinksIndex: string
}

export const algoliaConfig: AlgoliaConfig = {
  searchClient: getOptimizedClient(algoliaClient),
  recordsIndex: environment.algolia.recordsIndex,
  pageLinksIndex: environment.algolia.pageLinksIndex,
}
