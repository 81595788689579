import { environment } from '@config/environment'
import { SiteConfig, SiteDomain } from 'types'

export const siteNameStore = SiteConfig[environment.siteDomain as SiteDomain].siteName

export const splitProductDescription = (description: string) => {
  const name = description.split(':').length > 1 ? description.split(':')[0] : description || ''
  const color =
    description.split(':').length > 1
      ? description.split(':')[1].replace(' ', '').replace('Sample', '').trim()
      : description || ''

  return [name, color]
}
