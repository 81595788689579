import { AtImageProps } from '@curran-catalog/curran-atomic-library'
import { ContentfulComponentWithImages } from '@utils/common'
import { getCloudinarySrc } from '@utils/get-cloudinary-src'

import { NextImageWrapper } from '@utils/next-image-wrapper'
import { CloudinaryAsset, ContentfulAsset, ResourceType } from 'types'

export enum AssetSource {
  CLOUDINARY = 'cloudinary',
  CONTENTFUL = 'contentful',
}

export const normalizeCloudinaryAsset = (
  asset: CloudinaryAsset,
  contentType?: ContentfulComponentWithImages,
): Pick<AtImageProps, 'caption' | 'renderWrapper' | 'src' | 'alt' | 'width' | 'height'> & {
  thumbnailImage?: string
  isVideo?: boolean
} => {
  const isVideo = asset.resource_type === ResourceType.VIDEO
  return {
    isVideo,
    ...(isVideo && {
      thumbnailImage: getCloudinarySrc({
        asset,
        contentType,
      }).replace(/\s/g, '%20'),
    }),
    caption: asset.context?.custom?.caption ?? undefined,
    src: getCloudinarySrc({ asset, contentType, isVideo }).replace(/\s/g, '%20'),
    alt: asset.context?.custom.alt ?? '',
    renderWrapper: (className) =>
      NextImageWrapper({
        asset: { ...asset, className },
        contentType,
      }),
  }
}

export const normalizeContentfulAsset = (asset: ContentfulAsset) => ({
  src: asset.file.url,
  alt: asset.title,
})

export const normalizeAsset = ({
  asset,
  contentType,
  typeSource = AssetSource.CLOUDINARY,
}: {
  asset?: ContentfulAsset | CloudinaryAsset
  contentType?: ContentfulComponentWithImages
  typeSource?: AssetSource
}) => {
  if (!asset) return { src: '/images/image-coming-soon.jpg', alt: '' }

  switch (typeSource) {
    case AssetSource.CLOUDINARY:
      return normalizeCloudinaryAsset(asset as CloudinaryAsset, contentType)
    case AssetSource.CONTENTFUL:
      return normalizeContentfulAsset(asset as ContentfulAsset)
    default:
      return { src: '/images/image-coming-soon.jpg', alt: '' }
  }
}
