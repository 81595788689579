import { BLOCKS, Document, TopLevelBlock } from '@contentful/rich-text-types'
import { CloudinaryAsset } from 'types'
import { formatPrice } from './price'

export type PopoverRichTextProps = {
  description?: Document
  images?: CloudinaryAsset[]
  price?: number
  title?: string
}

export const getPopoverRichText = ({ description, images, price, title }: PopoverRichTextProps): Document => {
  const objDesc = description
    ? description.content
    : [{ nodeType: BLOCKS.PARAGRAPH, data: {}, content: [] } as TopLevelBlock]

  const objTitle = title
    ? [
        {
          nodeType: BLOCKS.PARAGRAPH,
          data: {},
          content: [
            {
              nodeType: 'text',
              value: title,
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
          ],
        } as TopLevelBlock,
      ]
    : [{ nodeType: BLOCKS.PARAGRAPH, data: {}, content: [] } as TopLevelBlock]

  return {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.EMBEDDED_ENTRY,
        data: {
          target: {
            sys: {
              contentType: {
                sys: {
                  id: 'atCloudinaryAsset',
                },
              },
            },
            fields: {
              name: 'Install Guides Header Image Wethersfield',
              image: images,
            },
          },
        },
        content: [],
      },
      ...objTitle,
      ...objDesc,
      {
        nodeType: BLOCKS.PARAGRAPH,
        data: {},
        content: [
          {
            nodeType: 'text',
            value: price ? `+ ${formatPrice(price)}` : '',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
        ],
      },
    ],
  }
}

export const getSpecsWeaveRichText = ({ itemTitle, itemValue }: { itemTitle: string; itemValue: string }): Document => {
  return {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.PARAGRAPH,
        data: {},
        content: [
          {
            nodeType: 'text',
            value: `${itemTitle}: `,
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: itemValue,
            marks: [],
            data: {},
          },
        ],
      },
    ],
  }
}

export const getEmptyRichText = (): Document => {
  return {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [],
  }
}
