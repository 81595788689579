import { DetectSiteDomain } from '@utils/detect-site-domain'
import { ContentfulObjFlooringWeave, ContentfulObjFurnitureProduct, ContentType } from 'types'
import {
  ColorSliderAnalyticsData,
  ProductMeasureEventDTO,
  ProductMeasureEventTypes,
} from '../../../../types/analytics/analytics-data'
import {
  colorSliderDataToDTO,
  objFlooringWeaveToProductMeasureDTO,
  objFurnitureProductToProductMeasureDTO,
} from '@utils/analytics/transformations/product-measure'
import { isColorSliderAnalyticsData } from '@utils/type-guards/is-color-slider-analytics-data'

export const createViewItemDTO = (
  data: ContentfulObjFlooringWeave | ContentfulObjFurnitureProduct | ColorSliderAnalyticsData,
  siteDomainInfo: DetectSiteDomain,
  index: number,
  price: number,
  previousRoute: string,
): ProductMeasureEventDTO => {
  if (isColorSliderAnalyticsData(data)) {
    return colorSliderDataToDTO(data, index, ProductMeasureEventTypes.VIEW_ITEM, previousRoute)
  }

  if (data.CONTENT_TYPE === ContentType.OBJ_FURNITURE_PRODUCT) {
    return objFurnitureProductToProductMeasureDTO(data, index, ProductMeasureEventTypes.VIEW_ITEM, price, previousRoute)
  }

  // if (data?.CONTENT_TYPE === ContentType.OBJ_FLOORING_WEAVE) {
  return objFlooringWeaveToProductMeasureDTO(
    data,
    index,
    siteDomainInfo,
    ProductMeasureEventTypes.VIEW_ITEM,
    price,
    previousRoute,
  )
}
