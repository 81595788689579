import { AtLinkProps, OrFooterProps } from '@curran-catalog/curran-atomic-library'

import { ContentfulOrFooter } from 'types'
import { normalizeLink, normalizeNewsLetter } from '.'

export const normalizeFooter = (footer: ContentfulOrFooter, path: string): OrFooterProps => {
  return {
    ...footer,
    newsLetter: normalizeNewsLetter(footer.newsletter),
    socialLinks: footer.socialMediaLinks?.map((socialLink) => normalizeLink(socialLink, false)) ?? [],
    menuItems:
      footer?.menuItems?.map(({ title, subItems }) => ({
        title: normalizeLink(title),
        subItems: subItems?.map((subItem) => {
          return {
            ...normalizeLink(subItem),
            active: path === subItem.actionUrl,
          } as AtLinkProps
        }),
      })) ?? [],
  }
}
