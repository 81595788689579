import { MlContentStripProps, MlVideoVariant, MlVideoProps } from '@curran-catalog/curran-atomic-library'

import { normalizeAsset } from './asset'
import { normalizeButton } from './button'
import { ContentfulMlContentStrip } from '../../types'
import { ContentfulComponentWithImages, DEFAULT_VIDEO_URL } from '@utils/common'

export const normalizeContentStrip = (
  contentStrip: ContentfulMlContentStrip,
  isPriorityImage?: boolean,
): MlContentStripProps => {
  const imageAsset =
    Array.isArray(contentStrip.fileImage) && contentStrip.fileImage.length > 0
      ? normalizeAsset({
          asset: { ...contentStrip.fileImage[0], priority: isPriorityImage },
          contentType: ContentfulComponentWithImages.mlContentStrip,
        })
      : { src: '/images/image-coming-soon.jpg', alt: '' }

  const mobileImageAsset =
    Array.isArray(contentStrip.fileMobileImage) && contentStrip.fileMobileImage.length > 0
      ? normalizeAsset({
          asset: { ...contentStrip.fileMobileImage[0], priority: isPriorityImage },
          contentType: ContentfulComponentWithImages.mlContentStrip,
        })
      : undefined

  const buttons = contentStrip.buttons ? contentStrip.buttons.map(normalizeButton) : []

  let videoThumbnail
  let videoUrl
  if (contentStrip.video) {
    videoThumbnail =
      Array.isArray(contentStrip.video.thumbnailImage) && contentStrip.video.thumbnailImage.length > 0
        ? normalizeAsset({
            asset: { ...contentStrip.video.thumbnailImage[0] },
            contentType: ContentfulComponentWithImages.mlVideo,
          })
        : undefined

    videoUrl =
      contentStrip.video.variant === MlVideoVariant.VIDEO_URL
        ? contentStrip.video.videoUrl ?? DEFAULT_VIDEO_URL.URL
        : contentStrip.video.videoFile && contentStrip.video.videoFile.length > 0
        ? contentStrip.video.videoFile[0].secure_url
        : DEFAULT_VIDEO_URL.FILE
  }

  return {
    ...contentStrip,
    image: imageAsset,
    video: contentStrip.video
      ? ({
          image: videoThumbnail,
          videoUrl: videoUrl,
          variant: contentStrip.video.variant,
          videoAttributes: {
            controls: !!contentStrip.video.showThumbnail,
            autoPlay: !contentStrip.video.showThumbnail,
            poster: contentStrip.video.showThumbnail && videoThumbnail ? videoThumbnail.src : undefined,
          },
        } as MlVideoProps)
      : undefined,
    mobileImage: mobileImageAsset,
    buttons,
  }
}
