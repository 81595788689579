import { SelectOption } from '@curran-catalog/curran-atomic-library'

import {
  feetInchesToFeetConversion,
  feetInchesToMConversion,
  getFeetInches,
} from '@components/rug-builder/order-rug/steps/customize-rug-types/utils'

export enum MeasureUnitKey {
  SQUARE_METER = 'sqm',
  LINEAR_METER = 'lm',
  SQUARE_YARD = 'sqyd',
  SQUARE_FEET = 'sqft',
  LINEAR_FEET = 'lft',
  LINEAR_FEET_IN = 'lftIn',
}

/** One feet contains 12 inches (his is equal to 30.48 centimeters), but for yup validations should be less than 12.  */
export const MAX_INCHES_VALUE = 11
export const DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE = 'Enter # less than 12'

export const getMeasureUnitMetricOptions = (): SelectOption[] => [
  { label: 'Square Meters', value: MeasureUnitKey.SQUARE_METER },
  { label: 'Linear Meters', value: MeasureUnitKey.LINEAR_METER },
]

export const getMeasureUnitImperialOptions = (): SelectOption[] => [
  { label: 'Square Yards', value: MeasureUnitKey.SQUARE_YARD },
  { label: 'Square Feet', value: MeasureUnitKey.SQUARE_FEET },
  { label: 'Linear Feet', value: MeasureUnitKey.LINEAR_FEET },
]

export const validateCurrentMeasureUnit = ({
  unitMeasure,
  sqm,
  lm,
  sqyd,
  sqft,
  lft,
}: {
  unitMeasure?: string
  sqm?: string
  lm?: string
  sqyd?: string
  sqft?: string
  lft?: string
}) => {
  switch (unitMeasure) {
    case MeasureUnitKey.SQUARE_METER: {
      return sqm
    }

    case MeasureUnitKey.LINEAR_METER: {
      return lm
    }

    case MeasureUnitKey.SQUARE_YARD: {
      return sqyd
    }

    case MeasureUnitKey.SQUARE_FEET: {
      return sqft
    }

    case MeasureUnitKey.LINEAR_FEET: {
      return lft
    }
  }
}

export const sendMeasureUnitObject = ({
  unitMeasure,
  sqm,
  lm,
  sqyd,
  sqft,
  lft,
  lftIn,
}: {
  unitMeasure?: string
  sqm?: string
  lm?: string
  sqyd?: string
  sqft?: string
  lft?: string
  lftIn?: string
}) => {
  switch (unitMeasure) {
    case MeasureUnitKey.SQUARE_METER: {
      return { sqm: Number(sqm ?? 0) }
    }

    case MeasureUnitKey.LINEAR_METER: {
      return { linearm: Number(lm ?? 0) }
    }

    case MeasureUnitKey.SQUARE_YARD: {
      return { sqyd: Number(sqyd ?? 0) }
    }

    case MeasureUnitKey.SQUARE_FEET: {
      return { sqft: Number(sqft ?? 0) }
    }

    case MeasureUnitKey.LINEAR_FEET: {
      return { linearft: feetInchesToFeetConversion(Number(lft ?? 0), Number(lftIn ?? 0)) }
    }
  }
}

export const getSquareMeterOrLinearConversion = ({
  isMetric,
  unitMeasure,
  rollWidth,
  sqm,
  lm,
  sqyd,
  sqft,
}: {
  isMetric: boolean
  unitMeasure: MeasureUnitKey
  rollWidth: number
  sqm?: string
  lm?: string
  sqyd?: string
  sqft?: string
}) => {
  if (isMetric) {
    switch (unitMeasure) {
      case MeasureUnitKey.SQUARE_METER: {
        const lengthM = Number(sqm ?? 0) / Number(feetInchesToMConversion(rollWidth))
        return `${lengthM.toFixed(2)}m`
      }

      case MeasureUnitKey.LINEAR_METER: {
        const rollWidthMeters = Number(feetInchesToMConversion(rollWidth))
        return `${(Number(lm ?? 0) * rollWidthMeters).toFixed(2)}m\u00B2`
      }

      default:
        return '-'
    }
  } else {
    switch (unitMeasure) {
      case MeasureUnitKey.SQUARE_YARD: {
        const sqft = Number(sqyd ?? 0) * 9
        const lengthFtIn = Number(sqft) / rollWidth
        const { feet, inches } = getFeetInches(lengthFtIn)
        return `${feet}' ${inches}''`
      }

      case MeasureUnitKey.SQUARE_FEET: {
        const lengthFtIn = Number(sqft ?? 0) / rollWidth

        const { feet, inches } = getFeetInches(lengthFtIn)
        return `${feet}' ${inches}''`
      }

      default:
        return '-'
    }
  }
}

export const getSquareYardConversion = ({
  rollWidth,
  lft,
  lftIn,
}: {
  rollWidth: number
  lft?: string
  lftIn?: string
}) => ((rollWidth * feetInchesToFeetConversion(Number(lft ?? 0), Number(lftIn ?? 0))) / 9).toFixed(1)

export const getFullConversionDetail = ({
  isMetric,
  unitMeasure,
  rollWidth,
  sqm,
  lm,
  sqyd,
  sqft,
  lft,
  lftIn,
}: {
  isMetric: boolean
  unitMeasure: MeasureUnitKey
  rollWidth: number
  sqm?: string
  lm?: string
  sqyd?: string
  sqft?: string
  lft?: string
  lftIn?: string
}) => {
  if (isMetric) {
    switch (unitMeasure) {
      case MeasureUnitKey.SQUARE_METER: {
        const lengthM = Number(sqm ?? 0) / Number(feetInchesToMConversion(rollWidth))
        return `${sqm ?? 0}m\u00B2 (${feetInchesToMConversion(rollWidth)}m x ${lengthM.toFixed(2)}m)`
      }

      case MeasureUnitKey.LINEAR_METER: {
        const rollWidthMeters = Number(feetInchesToMConversion(rollWidth))
        return `${(Number(lm ?? 0) * rollWidthMeters).toFixed(0)}m\u00B2 (${rollWidthMeters}m x ${Number(
          lm ?? 0,
        ).toFixed(2)}m)`
      }

      default:
        return '-'
    }
  } else {
    switch (unitMeasure) {
      case MeasureUnitKey.SQUARE_YARD: {
        const sf = Number(sqyd ?? 0) * 9
        const lengthFtIn = Number(sf) / rollWidth
        return `${getFeetInches(rollWidth).feet}' ${getFeetInches(rollWidth).inches}'' x ${
          getFeetInches(lengthFtIn).feet
        }' ${getFeetInches(lengthFtIn).inches}'' (${sqyd ?? 0}sy | ${sf}sf)`
      }

      case MeasureUnitKey.SQUARE_FEET: {
        const lengthFtIn = Number(sqft ?? 0) / rollWidth
        return `${getFeetInches(rollWidth).feet}' ${getFeetInches(rollWidth).inches}'' x ${
          getFeetInches(lengthFtIn).feet
        }' ${getFeetInches(lengthFtIn).inches}'' (${(Number(sqft ?? 0) / 9).toFixed(0)}sy | ${sqft ?? 0}sf)`
      }

      case MeasureUnitKey.LINEAR_FEET: {
        const lengthFeet = feetInchesToFeetConversion(Number(lft ?? 0), Number(lftIn ?? 0))
        const sf = (rollWidth * lengthFeet).toFixed(0)
        return `${getFeetInches(rollWidth).feet}' ${getFeetInches(rollWidth).inches}'' x ${lft ?? 0}' ${
          lftIn ?? 0
        }'' (${(Number(sf ?? 0) / 9).toFixed(0)}sy | ${sf}sf)`
      }

      default:
        return '-'
    }
  }
}
