import { OrFormSubmitResponse } from '@curran-catalog/curran-atomic-library'
import { ServicesToSendType } from '@utils/render'
import Router from 'next/router'
import { responseCodes, responseTypes } from 'types/api-forms'

const allowServices = (service: ServicesToSendType): string => {
  const servicesSelected = Object.keys(service).filter((key) => service[key].enabled)
  return servicesSelected.join(',')
}

export const submitForm = (formValues: FormData, serviceToSendData: ServicesToSendType, isRedirect: boolean) => {
  const services = allowServices(serviceToSendData)
  const formObject: { [key: string]: string } = {}
  formValues.forEach((value, key) => (formObject[key] = value as string))
  return new Promise<OrFormSubmitResponse>((resolve, reject) => {
    const headers = new Headers()
    headers.append('services-allow', services)

    if (services === 'hubspot') {
      const { portalId, formId } = serviceToSendData[services].data
      headers.append('form-id', formId)
      headers.append('portal-id', portalId)
    }

    fetch('/api/submit-form', {
      method: 'POST',
      headers,
      body: formValues,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.code === 200) {
          if (isRedirect) {
            const message = res.message.join(' ')
            localStorage.setItem('form-submitted-message', message)
            Router.push({ pathname: '/form-submitted' })
            return
          }
          resolve({ success: true, code: res.code, title: 'Submitted!', message: res.message })
        }
        resolve({
          success: false,
          code: res.code,
          title: 'Error!',
          message: res.message || res.error.message || responseCodes[responseTypes.GENERIC].message,
        })
      })
      .catch((error) => {
        reject({
          success: false,
          code: error.code || responseCodes[responseTypes.GENERIC].code,
          title: 'Error!',
          message:
            error.statusText ||
            `File size too large. Combined file size cannot exceed 4.5mb. Contact sales@curranonline.com for assistance. ${
              responseCodes[responseTypes.GENERIC].message
            }`,
        })
      })
  })
}
