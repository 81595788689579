import { OrTabsProps, OrTabItemProps, OrTabsVariant } from '@curran-catalog/curran-atomic-library'
import { ContentfulOrTabs, ContentfulOrTabItem } from 'types/contentful'
import { getRenderedBlocks } from '../render/blocks'

import { normalizeLink } from './link'

export const normalizeOrTabs = (tabs: ContentfulOrTabs): OrTabsProps => {
  return {
    tabs: tabs.tabs.map(normalizeOrTabItems),
    variant: OrTabsVariant.PRIMARY,
  }
}

export const normalizeOrTabItems = (tabItems: ContentfulOrTabItem): OrTabItemProps => {
  return {
    title: normalizeLink(tabItems?.title),
    blocks: tabItems?.blocks ? getRenderedBlocks(tabItems?.blocks) : undefined,
  }
}
