/* eslint-disable no-console */
import { captureMessage } from '@sentry/nextjs'
import { getFurniturePrices } from '@services/prices'
import { ContentfulObjFurnitureProduct, FurniturePrices, ErrorFurniturePrices } from 'types'

type FurniturePricesResponse = FurniturePrices | ErrorFurniturePrices | undefined

export const normalizeObjFurnitureProduct = async (
  product: ContentfulObjFurnitureProduct,
): Promise<ContentfulObjFurnitureProduct> => {
  const { mainCushions, addons, optionalCushionsGroup, addonGroups, ...rest } = product

  const hasMainCushions = Array.isArray(mainCushions) && mainCushions.length > 0
  const hasOptionalCushionsGroup =
    Array.isArray(optionalCushionsGroup) &&
    optionalCushionsGroup.length > 0 &&
    Array.isArray(optionalCushionsGroup[0].optionalCushions) &&
    optionalCushionsGroup[0].optionalCushions.length > 0

  const hasAddons = Array.isArray(addons) && addons.length > 0
  const hasAddonGroups =
    Array.isArray(addonGroups) &&
    addonGroups.length > 0 &&
    Array.isArray(addonGroups[0].addons) &&
    addonGroups[0].addons.length > 0

  let mainCushionPricesResponse: FurniturePricesResponse[] = []
  let optionalCushionsGroupPricesResponse: FurniturePricesResponse[] = []
  let addonPricesResponse: FurniturePricesResponse[] = []
  let addonGroupsPricesResponse: FurniturePricesResponse[] = []

  if (hasMainCushions) {
    const mainCushionPrices = mainCushions.map((mainCushion) =>
      getFurniturePrices({ contentfulId: mainCushion.CONTENTFUL_ID }),
    )
    mainCushionPricesResponse = await Promise.all(mainCushionPrices)
  }

  if (hasOptionalCushionsGroup) {
    const optionalCushionGroupPrices = optionalCushionsGroup.flatMap((optionalCushionGroup) =>
      optionalCushionGroup.optionalCushions.map((optionalCushion) =>
        getFurniturePrices({ contentfulId: optionalCushion.CONTENTFUL_ID }),
      ),
    )
    optionalCushionsGroupPricesResponse = await Promise.all(optionalCushionGroupPrices)
  }

  if (hasAddons) {
    const addonPrices = addons.map((addon) => getFurniturePrices({ contentfulId: addon.CONTENTFUL_ID }))
    addonPricesResponse = await Promise.all(addonPrices)
  }

  if (hasAddonGroups) {
    const addonGroupsPrices = addonGroups
      .filter((addonGroup) => Array.isArray(addonGroup.addons) && addonGroup.addons.length > 0)
      .flatMap((addonGroup) =>
        addonGroup?.addons?.map(({ CONTENTFUL_ID }) => getFurniturePrices({ contentfulId: CONTENTFUL_ID })),
      )
    addonGroupsPricesResponse = await Promise.all(addonGroupsPrices)
  }

  return {
    ...rest,
    ...(hasMainCushions && {
      mainCushions: mainCushions.map((mainCushion) => {
        const foundMainCushion = mainCushionPricesResponse.find((mainCushionResponse) => {
          if (
            mainCushionResponse &&
            Array.isArray((mainCushionResponse as FurniturePrices).products) &&
            (mainCushionResponse as FurniturePrices).products.length > 0
          ) {
            return (mainCushionResponse as FurniturePrices).productConnectorReference === mainCushion.CONTENTFUL_ID
          }
          return null
        })

        return {
          ...mainCushion,
          ...(Array.isArray(mainCushion.fabricGrades) &&
            mainCushion.fabricGrades.length > 0 && {
              fabricGrades: mainCushion.fabricGrades.map((fabricGrade) => ({
                ...fabricGrade,
                ...(Array.isArray(fabricGrade.fabrics) &&
                  fabricGrade.fabrics.length > 0 && {
                    fabrics: fabricGrade.fabrics.map((fabric) => {
                      const foundPrice = foundMainCushion
                        ? (foundMainCushion as FurniturePrices).products.find(
                            ({ grade }) => grade === fabric.fabricGradeSku,
                          )
                        : null

                      if (!foundPrice) {
                        captureMessage('Main cushion price not found', {
                          tags: {
                            fabricGradeSku: fabric.fabricGradeSku,
                            pricingApiResponse: JSON.stringify(foundMainCushion),
                          },
                        })

                        return fabric
                      } else {
                        return {
                          ...fabric,
                          price: foundPrice.price,
                          orderSku: foundPrice.sku,
                        }
                      }
                    }),
                  }),
              })),
            }),
        }
      }),
    }),
    ...(hasOptionalCushionsGroup && {
      optionalCushionsGroup: optionalCushionsGroup.map((optionalCushionGroup) => {
        return {
          ...optionalCushionGroup,
          ...(Array.isArray(optionalCushionGroup.optionalCushions) &&
            optionalCushionGroup.optionalCushions.length > 0 && {
              optionalCushions: optionalCushionGroup.optionalCushions.map((optionalCushion) => {
                const foundOptionalCushionsGroup = optionalCushionsGroupPricesResponse.find(
                  (optionalCushionsGroupPriceResponse) => {
                    if (
                      optionalCushionsGroupPriceResponse &&
                      Array.isArray((optionalCushionsGroupPriceResponse as FurniturePrices).products) &&
                      (optionalCushionsGroupPriceResponse as FurniturePrices).products.length > 0
                    ) {
                      return (
                        (optionalCushionsGroupPriceResponse as FurniturePrices).productConnectorReference ===
                        optionalCushion.CONTENTFUL_ID
                      )
                    }
                    return null
                  },
                )

                return {
                  ...optionalCushion,
                  ...(Array.isArray(optionalCushion.fabricGrades) &&
                    optionalCushion.fabricGrades.length > 0 && {
                      fabricGrades: optionalCushion.fabricGrades.map((fabricGrade) => ({
                        ...fabricGrade,
                        ...(Array.isArray(fabricGrade.fabrics) &&
                          fabricGrade.fabrics.length > 0 && {
                            fabrics: fabricGrade.fabrics.map((fabric) => {
                              const foundPrice = foundOptionalCushionsGroup
                                ? (foundOptionalCushionsGroup as FurniturePrices).products.find(
                                    ({ grade }) => grade === fabric.fabricGradeSku,
                                  )
                                : null

                              if (!foundPrice) {
                                captureMessage('Optional cushion price not found', {
                                  tags: {
                                    fabricGradeSku: fabric.fabricGradeSku,
                                    pricingApiResponse: JSON.stringify(foundOptionalCushionsGroup),
                                  },
                                })

                                return fabric
                              } else {
                                return {
                                  ...fabric,
                                  price: foundPrice.price,
                                  orderSku: foundPrice.sku,
                                }
                              }
                            }),
                          }),
                      })),
                    }),
                }
              }),
            }),
        }
      }),
    }),
    ...(hasAddons && {
      addons: addons?.map((addon) => {
        const foundAddons = addonPricesResponse.find((addonResponse) => {
          if (
            addonResponse &&
            Array.isArray((addonResponse as FurniturePrices).products) &&
            (addonResponse as FurniturePrices).products.length > 0
          ) {
            return (addonResponse as FurniturePrices).productConnectorReference === addon.CONTENTFUL_ID
          }
          return null
        })

        return {
          ...addon,
          ...(foundAddons && { addonPrices: foundAddons as FurniturePrices }),
        }
      }),
    }),
    ...(hasAddonGroups && {
      addonGroups: addonGroups?.map((addonGroup) => {
        return {
          ...addonGroup,
          addons: addonGroup?.addons?.map((addon) => {
            const foundAddons = addonGroupsPricesResponse.find((addonResponse) => {
              if (
                addonResponse &&
                Array.isArray((addonResponse as FurniturePrices).products) &&
                (addonResponse as FurniturePrices).products.length > 0
              ) {
                return (addonResponse as FurniturePrices).productConnectorReference === addon.CONTENTFUL_ID
              }
              return null
            })

            return {
              ...addon,
              ...(foundAddons && { addonPrices: foundAddons as FurniturePrices }),
            }
          }),
        }
      }),
    }),
  }
}
