import { ErrorResponse } from '@services/cart'
import { AxiosError } from 'axios'

export const formatPrice = (price: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: price % 1 !== 0 ? 2 : 0,
  }).format(price)

export const isPriceFetchingError = (e: AxiosError<ErrorResponse>) => {
  if (e.response) {
    // the error 422 occurs when the API body have, for example when the size is not correct
    if (e.response.status !== 422) return true
    return false
  }
}
