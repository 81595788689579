import { environment } from '@config/environment'
import { SiteDomain } from 'types'

export interface DetectSiteDomain {
  furnitureSite: boolean
  flooringSite: boolean
  sisalcarpetSite: boolean
  synsisalSite: boolean
}
export function detectSiteDomain(): DetectSiteDomain {
  const currentDomain = environment.siteDomain

  return {
    furnitureSite: currentDomain === SiteDomain.FURNITURE,
    flooringSite: currentDomain === SiteDomain.FLOORING,
    sisalcarpetSite: currentDomain === SiteDomain.SISALCARPET,
    synsisalSite: currentDomain === SiteDomain.SYNSISAL,
  }
}
