import { ReactNode } from 'react'
import {
  containerSectionHorizontalPaddingClasses,
  containerSectionVerticalPaddingClasses,
} from '@curran-catalog/curran-atomic-library'

export const ContainerWrapper = ({ children, className = '' }: { children: ReactNode; className?: string }) => {
  return (
    <section
      className={`tw-mx-auto tw-container ${containerSectionHorizontalPaddingClasses} ${containerSectionVerticalPaddingClasses} ${className}`}
    >
      {children}
    </section>
  )
}

export const ContainerWithoutVerticalPaddingWrapper = ({
  children,
  className = '',
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <section className={`tw-mx-auto tw-container ${containerSectionHorizontalPaddingClasses} ${className}`}>
      {children}
    </section>
  )
}
