import { environment } from '@config/environment'
import { Refinement, Sort } from 'types'

const indexName = environment.algolia.recordsIndex
export const hitsPerPage = 20
const NUMBER_OF_FACET_VALUES_TO_SHOW = 6
const NUMBER_OF_FACET_VALUES_MAX_TO_SHOW = 1000

export const sortsFlooring: Sort[] = [
  { value: indexName, label: 'Recommended', isDefault: true },
  { value: `${indexName}_newest`, label: 'Newest' },
  { value: `${indexName}_w2w_price_asc`, label: 'Price by SQYD: Low to High' },
  { value: `${indexName}_w2w_price_desc`, label: 'Price by SQYD: High to Low' },
]
export const sortsFurniture: Sort[] = [
  { value: indexName, label: 'Recommended', isDefault: true },
  { value: `${indexName}_newest`, label: 'Newest' },
  { value: `${indexName}_price_asc`, label: 'Price: Low to High' },
  { value: `${indexName}_price_desc`, label: 'Price: High to Low' },
]

export const furnitureRefinements: Refinement[] = [
  {
    type: 'category2Slug',
    header: 'category2Slug',
    label: 'category2Slug',
    hidden: true,
    options: {
      attribute: 'category2Slug',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'category2',
    header: 'category2',
    label: 'category2',
    hidden: true,
    options: {
      attribute: 'category2',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'category',
    header: 'category',
    label: 'Category',
    hidden: false,
    options: {
      attribute: 'category',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'categorySlug',
    header: 'categorySlug',
    label: 'CategorySlug',
    hidden: true,
    options: {
      attribute: 'categorySlug',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'vendorName',
    header: 'Brands',
    label: 'Brand',
    hidden: false,
    options: {
      attribute: 'vendorName',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'vendorSlug',
    header: 'vendorSlug',
    label: 'VendorSlug',
    hidden: true,
    options: {
      attribute: 'vendorSlug',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'collection',
    header: 'Collection',
    label: 'Collection',
    hidden: false,
    options: {
      attribute: 'collection',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'collectionSlug',
    header: 'CollectionSlug',
    label: 'CollectionSlug',
    hidden: true,
    options: {
      attribute: 'collectionSlug',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'materials',
    header: 'Material',
    label: 'Material',
    hidden: false,
    options: {
      attribute: 'materials',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'materialSlugs',
    header: 'MaterialSlugs',
    label: 'MaterialSlugs',
    hidden: true,
    options: {
      attribute: 'materialSlugs',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'colors',
    header: 'Colors',
    label: 'Color',
    hidden: false,
    options: {
      attribute: 'colors',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'price',
    header: 'Price',
    label: 'Price',
    hidden: false,
    options: {
      attribute: 'price',
    },
  },
]

export const flooringRefinements: Refinement[] = [
  {
    type: 'materialType',
    header: 'Material Type',
    label: 'Material Type',
    hidden: false,
    options: {
      attribute: 'materialType',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'materialTypeSlug',
    header: 'materialTypeSlug',
    label: 'materialTypeSlug',
    hidden: true,
    options: {
      attribute: 'materialTypeSlug',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'materialFamily',
    header: 'Material',
    label: 'Material',
    hidden: false,
    options: {
      attribute: 'materialFamily',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'materialFamilySlug',
    header: 'materialFamilySlug',
    label: 'materialFamilySlug',
    hidden: true,
    options: {
      attribute: 'materialFamilySlug',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'colors',
    header: 'Color',
    label: 'Color',
    hidden: false,
    options: {
      attribute: 'colors',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'patterns',
    header: 'Pattern',
    label: 'Pattern',
    hidden: false,
    options: {
      attribute: 'patterns',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'patternSlugs',
    header: 'patternSlugs',
    label: 'patternSlugs',
    hidden: true,
    options: {
      attribute: 'patternSlugs',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'texture',
    header: 'Texture',
    label: 'Texture',
    hidden: false,
    options: {
      attribute: 'texture',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'textureSlug',
    header: 'textureSlug',
    label: 'textureSlug',
    hidden: true,
    options: {
      attribute: 'textureSlug',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'style',
    header: 'Style',
    label: 'Style',
    hidden: false,
    options: {
      attribute: 'style',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'types',
    header: 'Type',
    label: 'Type',
    hidden: false,
    options: {
      attribute: 'types',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'typeSlugs',
    header: 'typeSlugs',
    label: 'typeSlugs',
    hidden: true,
    options: {
      attribute: 'typeSlugs',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'usages',
    header: 'Application | Use',
    label: 'Application | Use',
    hidden: false,
    options: {
      attribute: 'usages',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'usageSlugs',
    header: 'usageSlugs',
    label: 'usageSlugs',
    hidden: true,
    options: {
      attribute: 'usageSlugs',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
  {
    type: 'cleanability',
    header: 'Cleanability',
    label: 'Cleanability',
    hidden: false,
    options: {
      attribute: 'cleanability',
      showMore: true,
      limit: NUMBER_OF_FACET_VALUES_TO_SHOW,
      showMoreLimit: NUMBER_OF_FACET_VALUES_MAX_TO_SHOW,
    },
  },
]
