import { environment } from '@config/environment'
import axios from '@utils/axios'
import { ErrorFurniturePrices, FurniturePrices } from 'types'

export type FurniturePricesDTO = {
  contentfulId: string
}

export const getFurniturePrices = async ({ contentfulId }: FurniturePricesDTO) => {
  try {
    const response = await axios.get<FurniturePrices | ErrorFurniturePrices>(
      `/api/furniture/product-variations/${contentfulId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: environment.furnitureProductPricesApiKey ?? '',
        },
      },
    )
    return response.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
