import Axios from 'axios'
import { toast } from 'react-hot-toast'

import { environment } from '../config/environment'

const axios = Axios.create({
  baseURL: environment.backendURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  withXSRFToken: true,
})

axios.interceptors.response.use(
  function (response) {
    // Do nothing on success
    return response
  },
  function (error) {
    // Display generic error toast when error is not validation error
    // TODO: @Marco error.response.status doesn't exist. This never gets triggered. It also shows up a toast every
    // 3 seconds because of the user or cart validation
    if (error.response?.status >= 500) {
      toast.error('Something went wrong. Please try again later.')
    }

    return Promise.reject(error)
  },
)

export default axios
