import { ObjAddonState } from 'context'
import {
  ContentfulObjFlooringWeaveColor,
  ContentfulObjFurnitureFabric,
  ContentfulObjFurnitureFrameSlingCol,
  ContentType,
} from 'types'
import { formatPrice } from './price'

export const renderOptionName = (
  option:
    | ContentfulObjFurnitureFabric
    | ContentfulObjFurnitureFrameSlingCol
    | ObjAddonState
    | ContentfulObjFlooringWeaveColor,
) => {
  switch (option.CONTENT_TYPE) {
    case ContentType.OBJ_FURNITURE_FABRIC:
      return option.fabricName

    case ContentType.OBJ_FURNITURE_FRAMESLINGCOL:
      return option.frameSlingColName

    case ContentType.OBJ_ADDON:
      return ''

    case ContentType.OBJ_FLOORING_WEAVE_COLOR:
      return option.weaveColorName

    default:
      return ''
  }
}

export const renderOptionPrice = ({
  contentType,
  price = 0,
  startingPrice,
  cushionMinPrice,
}: {
  contentType: ContentType
  price?: number
  startingPrice: number
  cushionMinPrice: number
}) => {
  switch (contentType) {
    case ContentType.OBJ_FURNITURE_FABRIC_GRADE:
      return price && startingPrice && price - startingPrice ? `(+${formatPrice(price - startingPrice)})` : ''

    case ContentType.OBJ_FURNITURE_MAIN_CUSHION:
      return price && cushionMinPrice && price - cushionMinPrice ? `(+${formatPrice(price - cushionMinPrice)})` : ''

    default:
      return ''
  }
}
