import Image, { ImageProps } from 'next/image'

import { CloudinaryAsset } from 'types'
import { getCloudinarySrc } from './get-cloudinary-src'
import { ContentfulComponentWithImages, IMAGE_COMPONENTS_TRANSFORMATIONS } from './common'

const QUALITY = 70
const DEFAULT_DIMENSION = 1276

// priority for the elements that contains images at indexes 0, 1
const ELEMENT_INDEX_LIMIT = 1

export const validatePriorityImage = ({ index }: { index?: string }): boolean => {
  const firstLevelPosition = Number(index?.split('.')[0])

  return firstLevelPosition <= ELEMENT_INDEX_LIMIT
}

export const NextImageWrapper = ({
  asset,
  contentType,
}: {
  asset: Pick<ImageProps, 'className'> & CloudinaryAsset
  contentType?: ContentfulComponentWithImages
}) => {
  const { className = '', context, height, priority = false, public_id, secure_url, width } = asset

  const cloudinarySrc = getCloudinarySrc({ asset })

  const imageWidthDefault =
    contentType && IMAGE_COMPONENTS_TRANSFORMATIONS[contentType]
      ? IMAGE_COMPONENTS_TRANSFORMATIONS[contentType].width
      : DEFAULT_DIMENSION

  return (
    <Image
      priority={priority}
      {...(!priority && { loading: 'lazy' })}
      src={public_id ? cloudinarySrc : secure_url}
      quality={QUALITY}
      alt={context?.custom.alt ?? ''}
      className={className ?? ''}
      width={width > imageWidthDefault ? imageWidthDefault : width}
      height={height}
      style={{ objectFit: 'cover' }}
    />
  )
}
