export const handleComboHierarchy = (
  hierarchy: number,
  primaryComboDispatch: () => void,
  secondaryComboDispatch: () => void,
  tertiaryComboDispatch: () => void,
  quaternaryComboDispatch: () => void,
) => {
  if (hierarchy === 1) {
    primaryComboDispatch()
  } else if (hierarchy === 2) {
    secondaryComboDispatch()
  } else if (hierarchy === 3) {
    tertiaryComboDispatch()
  } else if (hierarchy === 4) {
    quaternaryComboDispatch()
  }
}
