import { memo, ReactNode, useCallback } from 'react'
import { InstantSearch, useSearchBox } from 'react-instantsearch'
import { OrSearch } from '@curran-catalog/curran-atomic-library'

import isEqual from 'react-fast-compare'
import { useRouter } from 'next/router'
import { AlgoliaConfig } from '@utils/index'

export const CustomOrSearch = memo(({ placeholder, results }: { placeholder?: string; results?: JSX.Element }) => {
  const router = useRouter()
  const { query, refine } = useSearchBox()

  const handleSubmit = useCallback(
    (inputValue?: string) => {
      router.replace(encodeURI(`/catalog?query=${inputValue}`))
    },
    [router],
  )

  return (
    <OrSearch
      placeholder={placeholder}
      results={results}
      refine={refine}
      onRedirect={handleSubmit}
      currentRefinement={query}
    />
  )
}, isEqual)

export const AlgoliaWrapper = ({ algoliaConfig, children }: { algoliaConfig: AlgoliaConfig; children: ReactNode }) => {
  return (
    <InstantSearch
      searchClient={algoliaConfig.searchClient}
      insights={true}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      {children}
    </InstantSearch>
  )
}
