import { memo, PropsWithChildren } from 'react'
import isEqual from 'react-fast-compare'
import { Configure, Highlight, Index, useHits, useInstantSearch } from 'react-instantsearch'
import { AtIcon, AtLink, AtLinkVariant, LinkTarget } from '@curran-catalog/curran-atomic-library'
import { Hit, BaseHit } from 'instantsearch.js'
import Image from 'next/image'

import { renderLinkWrapper } from '@utils/link-wrapper'
import { PRODUCT_PAGE_SLUG } from '@utils/normalize'
import { CloudinaryAsset } from 'types'
import { setAlgoliaQueryIdHistory } from '@utils/algolia'

type ResultsProps = {
  recordsIndex: string
  pageLinksIndex: string
  noResultsFoundLabel: string
  seeAllResultsLabel: string
  pageNoResultsLabel: string
  pageResultsLabel: string
}

type ProductResultsWrapper = {
  noResultsFoundLabel: string
  seeAllResultsLabel: string
  indexName: string
}
type PageResultsWrapper = {
  pageNoResultsLabel: string
  pageResultsLabel: string
  indexName: string
}

interface SearchResultsHit extends Hit<BaseHit> {
  name?: string
  images?: CloudinaryAsset[]
}

interface PageLinksHit extends Hit<BaseHit> {
  title?: string
  sites?: string[]
  internalLink?: string
  externalLinks?: string[]
}

interface PageLinksHit extends Hit<BaseHit> {
  title?: string
  sites?: string[]
  internalLink?: string
  externalLinks?: string[]
}

const ProductResultsWrapper = memo(
  ({ children, seeAllResultsLabel, noResultsFoundLabel, indexName }: PropsWithChildren<ProductResultsWrapper>) => {
    const { status, error, uiState } = useInstantSearch({ catchError: true })
    const { hits, results, sendEvent } = useHits()
    const query = uiState[''].query
    const isSearchIdle = status === 'idle'
    const availableHits = hits?.length > 0
    const showAllResultsLink = results?.nbHits ? results.nbHits > 4 : false

    if ((error || !availableHits) && query && isSearchIdle) {
      return (
        <p className="w-full">
          {noResultsFoundLabel} <span>&ldquo;{query}&rdquo;</span>
        </p>
      )
    }

    return (
      <div id="index-1" data-insights-index={indexName}>
        {!isSearchIdle ? (
          <p className="w-full">Loading...</p>
        ) : (
          <div className="flex flex-col gap-1">
            <p className="w-full text-xs mb-4 font-medium">{seeAllResultsLabel}</p>

            <ul className="max-h-96  overflow-y-scroll flex flex-col gap-4">
              {hits.map((hit: SearchResultsHit) => {
                const firstImage = hit.images?.length ? hit.images[0].url : '/images/image-coming-soon.jpg'

                return (
                  <li
                    key={hit.objectID}
                    data-insights-object-id={hit.objectID}
                    data-insights-position={hit.__position}
                    data-insights-query-id={hit.__queryID}
                  >
                    <AtLink
                      linkWrapper={renderLinkWrapper}
                      actionUrl={`/${PRODUCT_PAGE_SLUG}/${hit.nameSlug}`}
                      className="tw-w-full tw-text-dark tw-text-sm !gap-4"
                      id={`algolia-product-result-link-${hit.objectID}`}
                      onClick={() => {
                        sendEvent('click', hit, 'Product Result Clicked')
                        setAlgoliaQueryIdHistory(hit)
                      }}
                    >
                      <div className="w-[45px]">
                        <Image
                          src={firstImage}
                          alt={hit.name ?? 'search-box-result'}
                          loading="lazy"
                          layout="fixed"
                          width={45}
                          height={45}
                        />
                      </div>
                      <Highlight
                        highlightedTagName="em"
                        classNames={{
                          highlighted: 'font-medium',
                        }}
                        attribute="name"
                        hit={hit}
                      />
                    </AtLink>
                  </li>
                )
              })}
            </ul>
            {showAllResultsLink && (
              <AtLink
                linkWrapper={renderLinkWrapper}
                variant={AtLinkVariant.DEFAULT}
                actionUrl={`/catalog?query=${query}`}
              >
                <span className="text-xs">
                  See all ({results?.nbHits}) results for &rdquo;{query}&rdquo;
                </span>
              </AtLink>
            )}
            {children}
          </div>
        )}
      </div>
    )
  },
  isEqual,
)

const PageResultsWrapper = memo(
  ({ children, pageNoResultsLabel, pageResultsLabel, indexName }: PropsWithChildren<PageResultsWrapper>) => {
    const { status, error, uiState } = useInstantSearch({ catchError: true })
    const { hits, results, sendEvent } = useHits()
    const query = uiState[''].query
    const isSearchIdle = status === 'idle'
    const availableHits = hits?.length > 0
    const showAllResultsLink = results?.nbHits ? results.nbHits > 4 : false

    if ((error || !availableHits) && query && isSearchIdle) {
      return (
        <p className="w-full">
          {pageNoResultsLabel} <span>&ldquo;{query}&rdquo;</span>
        </p>
      )
    }

    return (
      <div id="index-2" data-insights-index={indexName}>
        {!isSearchIdle ? (
          <p className="w-full">Loading...</p>
        ) : (
          <div className="flex flex-col gap-1">
            <p className="w-full text-xs mb-4 font-medium">{pageResultsLabel}</p>

            <ul className="max-h-96  overflow-y-scroll flex flex-col gap-4">
              {hits.map((hit: PageLinksHit) => {
                const isInternalLink = hit.internalLink
                const actionUrl = isInternalLink ? `/${hit.internalLink}` : hit.externalLinks?.[0]
                const target = isInternalLink ? LinkTarget.SELF : LinkTarget.BLANK

                return (
                  <li
                    key={hit.objectID}
                    data-insights-object-id={hit.objectID}
                    data-insights-position={hit.__position}
                    data-insights-query-id={hit.__queryID}
                  >
                    <AtLink
                      linkWrapper={renderLinkWrapper}
                      actionUrl={actionUrl}
                      className="tw-w-full tw-text-dark tw-text-sm tw-no-underline !gap-1"
                      target={target}
                      id={`algolia-page-result-link-${hit.objectID}`}
                      onClick={() => sendEvent('click', hit, 'Page Result Clicked')}
                    >
                      <Highlight
                        highlightedTagName="em"
                        classNames={{
                          highlighted: 'font-medium',
                        }}
                        attribute="title"
                        hit={hit}
                      />
                      {!isInternalLink && <AtIcon type="external-link" size={16} />}
                    </AtLink>
                  </li>
                )
              })}
            </ul>
            {showAllResultsLink && (
              <AtLink
                linkWrapper={renderLinkWrapper}
                variant={AtLinkVariant.DEFAULT}
                actionUrl={`/page-results?query=${query}`}
              >
                <span className="text-xs">
                  See all ({results?.nbHits}) results for &rdquo;{query}&rdquo;
                </span>
              </AtLink>
            )}
            {children}
          </div>
        )}
      </div>
    )
  },
  isEqual,
)

export const Results = ({
  recordsIndex,
  pageLinksIndex,
  noResultsFoundLabel,
  seeAllResultsLabel,
  pageNoResultsLabel,
  pageResultsLabel,
}: ResultsProps) => {
  return (
    <>
      <Index indexName={recordsIndex}>
        <Configure clickAnalytics={true} hitsPerPage={4} />
        <ProductResultsWrapper
          noResultsFoundLabel={noResultsFoundLabel}
          seeAllResultsLabel={seeAllResultsLabel}
          indexName={recordsIndex}
        ></ProductResultsWrapper>
      </Index>
      <hr role="separator" className="border-outlined-gray my-4" />
      <Index indexName={pageLinksIndex}>
        <Configure clickAnalytics={true} hitsPerPage={5} />
        <PageResultsWrapper
          pageNoResultsLabel={pageNoResultsLabel}
          pageResultsLabel={pageResultsLabel}
          indexName={pageLinksIndex}
        ></PageResultsWrapper>
      </Index>
    </>
  )
}
