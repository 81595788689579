import { ReactNode } from 'react'
import { ContainerWithoutVerticalPaddingWrapper, ContainerWrapper } from './container-wrapper'
import { ContainerFluidWithoutVerticalPaddingWrapper, ContainerFluidWrapper } from './container-fluid-wrapper'

export const getWrapper = (isContainered: boolean, isFluid: boolean, withoutVerticalPaddings?: boolean) =>
  isContainered
    ? isFluid
      ? withoutVerticalPaddings
        ? ContainerFluidWithoutVerticalPaddingWrapper
        : ContainerFluidWrapper
      : withoutVerticalPaddings
      ? ContainerWithoutVerticalPaddingWrapper
      : ContainerWrapper
    : ({ children }: { children: ReactNode }) => <>{children}</>
