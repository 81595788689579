import { AtButtonProps } from '@curran-catalog/curran-atomic-library'

import { ContentfulAtButton } from 'types'
import { normalizeAsset } from '.'

export const normalizeButton = (button?: ContentfulAtButton): AtButtonProps => {
  const icon = button?.iconImage ? normalizeAsset({ asset: button?.iconImage[0] }) : undefined
  return {
    label: button?.label ?? '',
    variant: button?.type,
    size: button?.size,
    target: button?.target,
    actionType: button?.actionType,
    actionValue: button?.actionValue,
    ...((button?.iconType || button?.iconImage) && {
      icon: {
        ...icon,
        type: button?.iconType,
      },
    }),
  }
}
