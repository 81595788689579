import { ContentfulObjAnalytics } from '../../../../types'
import { FlooringCatalogHit, FurnitureCatalogHit } from '../../../../types/algolia/catalog-index'
import { DetectSiteDomain } from '@utils/detect-site-domain'
import { isObjAnalytics } from '@utils/type-guards/is-obj-analytics'
import {
  flooringCatalogHitToProductMeasureDTO,
  furnitureCatalogHitToProductMeasureDTO,
  objAnalyticsToProductMeasureDTO,
} from '@utils/analytics/transformations/product-measure'
import { ProductMeasureEventDTO, ProductMeasureEventTypes } from '../../../../types/analytics/analytics-data'

export const createViewItemListDTO = (
  data: ContentfulObjAnalytics | FlooringCatalogHit | FurnitureCatalogHit,
  siteDomainInfo: DetectSiteDomain,
  index: number,
): ProductMeasureEventDTO => {
  const { furnitureSite } = siteDomainInfo

  // Synsisal Site - Obj Analytics
  if (isObjAnalytics(data)) {
    return objAnalyticsToProductMeasureDTO(data, index, siteDomainInfo, ProductMeasureEventTypes.VIEW_ITEM_LIST)
  }

  // Curran Home Furniture Site - FurnitureCatalogHit
  if (furnitureSite) {
    return furnitureCatalogHitToProductMeasureDTO(
      data as FurnitureCatalogHit,
      index,
      ProductMeasureEventTypes.VIEW_ITEM_LIST,
    )
  }

  // Flooring Sites - FlooringCatalogHit
  return flooringCatalogHitToProductMeasureDTO(
    data as FlooringCatalogHit,
    index,
    siteDomainInfo,
    ProductMeasureEventTypes.VIEW_ITEM_LIST,
  )
}
