import { environment } from '@config/environment'
import { CloudinaryAsset, CloudinaryFormat, ResourceType } from 'types'
import { ContentfulComponentWithImages, IMAGE_COMPONENTS_TRANSFORMATIONS } from './common'

export const DEFAULT_DIMENSION = 1276

export const getCloudinarySrc = ({
  asset,
  contentType,
  isVideo,
}: {
  asset: CloudinaryAsset
  contentType?: ContentfulComponentWithImages
  isVideo?: boolean
}) => {
  const { format, public_id, raw_transformation, resource_type, type, version, width } = asset

  const custom_raw_transformation =
    CloudinaryFormat.SVG === format ? raw_transformation.replace('f_auto', 'f_svg') : raw_transformation

  const imageWidthDefault =
    contentType && IMAGE_COMPONENTS_TRANSFORMATIONS[contentType]
      ? IMAGE_COMPONENTS_TRANSFORMATIONS[contentType].width
      : DEFAULT_DIMENSION

  // https://res.cloudinary.com/<cloud_name>/<asset_type>/<delivery_type>/<transformations>/<version>/<public_id_full_path>.<extension>
  const extension =
    resource_type === ResourceType.VIDEO && isVideo
      ? CloudinaryFormat.MP4
      : CloudinaryFormat.SVG === format
      ? CloudinaryFormat.SVG
      : CloudinaryFormat.WEBP
  return `https://res.cloudinary.com/${
    environment.cloudinary.cloudName
  }/${resource_type}/${type}/${custom_raw_transformation}/w_${
    width > imageWidthDefault ? imageWidthDefault : width
  }/v${version}/${public_id}.${extension}`
}
