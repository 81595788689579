import { RATE_LIMIT_MINUTES } from '@utils/common'
import { File } from 'formidable'

export enum responseTypes {
  SUCCESS = 'success',
  RECAPTCHA = 'recaptcha',
  RATE_LIMIT = 'rateLimit',
  SERVICE_NOT_FOUND = 'serviceNotFound',
  GENERIC = 'generic',
}

export type DataResponseType = File & {
  id: string
  name: string
  type: string
  path: string
  access: string
}

export type ResponseType = {
  message: string | string[]
  status: number
  data: DataResponseType | File | string
  success: boolean
  title?: string
}

export const responseCodes: Record<responseTypes, { code: number; message: string }> = {
  success: {
    code: 200,
    message: 'Thank you for your inquiry. We will be in touch very soon.',
  },
  recaptcha: {
    code: 422,
    message: 'Unprocessable request, Invalid captcha code',
  },
  rateLimit: {
    code: 429,
    message: `You just submitted a request. If you have additional questions please resubmit after ${RATE_LIMIT_MINUTES} minutes.`,
  },
  generic: {
    code: 500,
    message: 'Server error, please try again later',
  },
  serviceNotFound: {
    code: 422,
    message: 'Unprocessable Entity',
  },
}
