import { ContentfulObjFlooringWeaveColor } from '../contentful'

export interface TrackEventDTO {
  affiliation: string
  currency: string
  index?: number | undefined
  itemBrand?: 'synsisal' // Attribute only for Synsisal
  itemCategory: string // Synsisal: Custom Rugs Samples
  itemId: string
  itemListName: string
  itemName: string
  itemStockDetail: string
  itemVariant?: string
  price?: number
  quantity?: number
}

export interface ProductMeasureEventDTO {
  event: ProductMeasureEventTypes
  ecommerce: {
    item_list_name?: string
    currency?: string
    items: {
      affiliation?: string
      currency: string
      index?: number
      item_brand?: string
      item_category2?: string
      item_category?: string
      item_id?: string
      item_list_name?: string
      item_name?: string
      item_stock_detail?: string
      item_variant?: string
      quantity?: number
      price?: number
    }[]
  }
}

export interface CheckoutEventDTO {
  event: CheckoutEventTypes
  ecommerce?: {
    affiliation?: string
    coupon?: string
    currency?: string
    item_list_name?: string
    payment_type?: string
    shipping?: number
    shipping_tier?: string
    tax?: number
    transaction_id?: string
    value?: number | string // Value or  "Free"
    items: {
      affiliation?: string
      coupon?: string
      currency: string
      index?: number
      item_brand?: string
      item_category2?: string // Optional since it's not present in all items
      item_category3?: string // Optional
      item_category4?: string // Optional
      item_category5?: string // Optional
      item_category?: string
      item_id?: string
      item_list_name?: string
      item_name?: string
      item_stock_detail?: string
      item_variant?: string
      price?: number
      quantity?: number
    }[]
  }
}

export interface CartEventDTO {
  event: CartEventTypes
  ecommerce?: {
    currency?: string
    items: {
      affiliation?: string
      currency: string
      index?: number
      item_brand?: string
      item_category?: string
      item_id?: string
      item_list_name: string
      item_name: string
      item_stock_detail?: string
      item_variant: string
      price: number
      quantity?: number
    }[]
  }
}

export enum ProductMeasureEventTypes {
  SELECT_ITEM = 'select_item',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
}

export enum CheckoutEventTypes {
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
}

export enum CartEventTypes {
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',
}

export interface ColorSliderAnalyticsData {
  weaveName?: string
  selectedColor: ContentfulObjFlooringWeaveColor
}
