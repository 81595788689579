import { WeaveParametersResponse, WeaveParametersSuccess } from '@services/prices'

export const getFeetInches = (feetInchValue: number) => {
  const feet = Math.floor(feetInchValue)
  const inches = Math.floor((feetInchValue - feet) * 12)

  return { feet, inches }
}
export const feetInchesToMConversion = (ftInchValues: number) => {
  const { feet, inches } = getFeetInches(ftInchValues)
  // To convert feet to meters, multiply your figure by 0.3048
  // https://www.feettometres.com/
  const meter = feet * 0.3048
  // To convert inches to meters, multiply your figure by 0.0254
  const centimeter = inches * 0.0254

  return (meter + centimeter).toFixed(2)
}

export const feetInchesToCmConversion = (ftInchValues: number) => Math.round(ftInchValues * 30.48)
export const cmToFeetConversion = (cmValue: number) => (cmValue / 30.48).toFixed(3)

export const cmToFeetInchesConversion = (cmValue: number) => {
  const realFeet = (cmValue * 0.3937) / 12
  const { feet, inches } = getFeetInches(realFeet)
  return `${feet}' ${inches}''`
}

export const stringFeetInchesToCmConversion = (ftInchStringValue: string) => {
  const feetInchesRegex = /^(\d+)'(\d+)(?:''|")$/
  const match = feetInchesRegex.exec(ftInchStringValue)
  let feet = 0
  let inches = 0

  if (match) {
    feet = parseInt(match[1], 10)
    inches = parseInt(match[2], 10)

    return Math.round((feet * 12 + inches) * 2.54)
  }

  return 0
}

export const feetInchesToFeetConversion = (feetValue: number, inchValue: number) => {
  const inchToFeet = inchValue / 12
  return Number((feetValue + inchToFeet).toFixed(3))
}

export const rectangleSqFtConversion = ({
  widthFt = 0,
  widthIn = 0,
  lengthFt = 0,
  lengthIn = 0,
}: {
  widthFt: number
  widthIn: number
  lengthFt: number
  lengthIn: number
}) => {
  const widthFeet = feetInchesToFeetConversion(widthFt, widthIn)
  const lengthFeet = feetInchesToFeetConversion(lengthFt, lengthIn)
  return widthFeet * lengthFeet
}

export const sqFtToM2Conversion = ({ sqFt }: { sqFt: number }) => Number((sqFt / 10.764).toFixed(2))

export const sqFtToCmConversion = ({ sqFt }: { sqFt: number }) => {
  const m2 = sqFtToM2Conversion({ sqFt })
  return Number((m2 * 10000).toFixed(3))
}

export const rectangleCmConversion = ({ widthCm = 0, lengthCm = 0 }: { widthCm: number; lengthCm: number }) => {
  return widthCm * lengthCm
}

export const getRoundedUpRollWidth = (parameters?: WeaveParametersResponse) => {
  if (!!parameters && (parameters as WeaveParametersSuccess)['roll_width']) {
    const rollWidth = Number((parameters as WeaveParametersSuccess)['roll_width']) ?? 0
    const roundedNumber = Math.ceil(rollWidth * 10) / 10
    return roundedNumber.toString()
  }
  return null
}
