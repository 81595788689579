import { ReactNode } from 'react'
import {
  AtImage,
  AtInfoItem,
  AtLink,
  AtLinkVariant,
  MlRichTextProps,
  OrStepper,
} from '@curran-catalog/curran-atomic-library'
import { Block, Inline } from '@contentful/rich-text-types'

import { CloudinaryAsset, ContentfulAtLink, ContentfulMlRichText, ContentType } from 'types'
import { normalizeInfoItem, normalizeAsset } from '.'
import { normalizeOrStepper } from '.'
import { cleanContentfulEntry } from '..'
import { normalizeLink } from './link'

export const normalizeRichText = (richText: ContentfulMlRichText): MlRichTextProps => {
  const richTextContent = {
    ...richText,
    text: richText.content,
    customRenderNode: {
      'embedded-entry-block': (node: Block | Inline, children: ReactNode) => {
        if (node.data.target.sys.contentType.sys.id === ContentType.AT_CLOUDINARY_ASSET) {
          return node.data.target?.fields?.image?.map((img: CloudinaryAsset, idx: number) => (
            <AtImage key={idx} {...normalizeAsset({ asset: img })} className="w-fit mx-auto" />
          ))
        }

        if (node.data.target.sys.contentType.sys.id === ContentType.AT_INFO_ITEM && node.data.target?.fields)
          return <AtInfoItem {...normalizeInfoItem(node.data.target?.fields)} />

        if (node.data.target.sys.contentType.sys.id === ContentType.OR_STEPPER)
          return <OrStepper {...normalizeOrStepper(cleanContentfulEntry(node.data.target))} />
        if (node.data.target.sys.contentType.sys.id === ContentType.AT_LINK) {
          const normalizedLink = normalizeLink(node.data.target.fields as ContentfulAtLink)
          return (
            <AtLink
              variant={AtLinkVariant.DEFAULT}
              className="max-w-max"
              imageClassName="!w-96 !h-96"
              {...normalizedLink}
            />
          )
        }

        return children
      },
      'embedded-entry-inline': (node: Block | Inline, children: ReactNode) => {
        if (node.data.target.sys.contentType.sys.id === ContentType.AT_LINK) {
          const normalizedLink = normalizeLink(node.data.target.fields as ContentfulAtLink)

          return <AtLink variant={AtLinkVariant.DEFAULT} {...normalizedLink} />
        }
        return children
      },
    },
  }

  return richTextContent
}
