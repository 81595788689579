import { ReactNode } from 'react'
import { containerSectionVerticalPaddingClasses } from '@curran-catalog/curran-atomic-library'

export const ContainerFluidWrapper = ({ children, className = '' }: { children: ReactNode; className?: string }) => {
  return (
    <section className={`tw-max-w-screen-xl tw-mx-auto ${containerSectionVerticalPaddingClasses} ${className}`}>
      {children}
    </section>
  )
}

export const ContainerFluidWithoutVerticalPaddingWrapper = ({
  children,
}: {
  children: ReactNode
  className?: string
}) => {
  return <section className="tw-max-w-screen-xl tw-mx-auto">{children}</section>
}
