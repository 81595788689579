import { MlFormFieldProps } from '@curran-catalog/curran-atomic-library'
import { ContentfulMlFormField } from '../../types'

export const normalizeFormField = (formField: ContentfulMlFormField): MlFormFieldProps => {
  const {
    fieldId,
    formFieldType,
    label,
    options,
    helperMessage,
    errorMessage,
    required,
    placeholder,
    readOnly,
    allowLetterOnly,
    min,
    max,
    maxLength,
    minLength,
    accept,
    multiple,
    showConditionName,
    showConditionValue,
    childSelect,
    hiddenField,
    defaultFieldValue,
  } = formField

  return {
    fieldId,
    type: formFieldType,
    label,
    name: fieldId,
    options,
    helperMessage,
    errorMessage,
    required,
    placeholder,
    readOnly,
    allowLetterOnly,
    min,
    max,
    maxLength,
    minLength,
    accept,
    multiple,
    hiddenField,
    defaultFieldValue,
    showCondition: showConditionName && showConditionValue ? `${showConditionName}=${showConditionValue}` : undefined,
    childSelect: childSelect ? normalizeFormField(childSelect) : undefined,
  }
}
