import { AtLinkProps } from '@curran-catalog/curran-atomic-library'
import { ContentfulAtLink } from 'types/contentful'

import { normalizeAsset } from '.'
import { renderLinkWrapper } from '..'

export const normalizeLink = (link?: ContentfulAtLink, isPriorityImage = false): AtLinkProps => {
  const iconLink =
    link && Array.isArray(link.iconImage) && link.iconImage.length > 0
      ? normalizeAsset({ asset: { ...link.iconImage[0], priority: isPriorityImage } })
      : undefined
  const imageLink =
    link && Array.isArray(link.fileImage) && link.fileImage.length > 0
      ? { ...normalizeAsset({ asset: { ...link.fileImage[0], priority: isPriorityImage } }), caption: undefined }
      : undefined

  return {
    label: link?.label,
    actionUrl: link?.actionUrl,
    target: link?.target,
    icon: iconLink && { ...iconLink },
    iconPosition: link?.iconPosition,
    image: imageLink && { ...imageLink },
    ariaLabel: link?.label ?? link?.name,
    linkWrapper: renderLinkWrapper,
    ...(link?.rel && link.rel.length > 0 && { rel: link.rel.join(' ') }),
  }
}
