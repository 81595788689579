import { ReactNode } from 'react'
import Link from 'next/link'

export const renderLinkWrapper = (children: ReactNode, href?: string) =>
  href ? (
    <Link href={href} legacyBehavior>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
